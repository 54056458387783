import { Anchor, Box, Container, Flex, Group, Stack } from '@mantine/core';
import { Hero } from "./Hero";
import { PricingCards } from "./Pricing";
import { FeaturesGrid } from "./Features";
import { Header } from "./Header";
import { AuthStateOptions } from '.././components/authentication/Authenticate';
import React from "react";

const footerLinks = [
    { link: 'mailto:graceraper@gmail.com', label: 'Contact' },
    // { link: '#', label: 'Privacy' },
    // { link: '#', label: 'Blog' },
    // { link: '#', label: 'Careers' },
];

function Footer() {
    const items = footerLinks.map((link) => (
        <Anchor<'a'>
            c="dimmed"
            key={link.label}
            href={link.link}
            size="sm"
        >
            {link.label}
        </Anchor>
    ));

    return (
        <Box w="100%" bg="#f5f5f5" px={40} pb={20} pt="md"> {/* Grey background for footer */}
            <Container fluid>
                <Flex justify="space-between" align="center">
                    <img src="./logo.png" alt="Company Logo" style={{ width: 52 }} />
                    <Group>{items}</Group>
                </Flex>
            </Container>
        </Box>
    );
}

interface LandingPageProps {
    onAuthAction: (authState: AuthStateOptions.Login | AuthStateOptions.JoinWaitlist) => void; // Prop to handle authentication action
}

export const LandingPage: React.FC<LandingPageProps> = ({ onAuthAction }) => {
    return (
        <Stack
            bg="var(--mantine-color-body)"
            align="stretch"
            justify="flex-start"
            gap={0}
        >
            <Header onLoginClick={() => onAuthAction(AuthStateOptions.Login)} onRegisterClick={() => onAuthAction(AuthStateOptions.JoinWaitlist)}/>
            <div id="hero">
                <Hero onCallToActionClick={() => onAuthAction(AuthStateOptions.JoinWaitlist)}/>
            </div>
            <div id="features">
                <FeaturesGrid />
            </div>
            <div id="pricing">
                <PricingCards />
            </div>
            <Footer />
        </Stack>
    );
}