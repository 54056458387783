import React, {useState, useEffect} from 'react';
import {
    Text,
    Fieldset,
    Card,
    Box,
    Image,
    Paper,
    Group,
    Button,
    Flex,
    Autocomplete,
    Tooltip,
    resolveClassNames
} from '@mantine/core';
import {IconCheck, IconX, IconTrash, IconPencil, IconStack2} from '@tabler/icons-react';
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";
import axios from "axios";
import {NamedFaceInImage, UnnamedFaceInImage, FaceStack} from "../types/Face";

interface People {
    person_name: string;
    person_key: string;
}

interface FaceDataFromImages {
    people: People[];
    named_faces: NamedFaceInImage[];
    unnamed_faces: UnnamedFaceInImage[];
}

interface ConfirmFacesModalContentProps {
    userId: string;
    auth: AuthenticationResultType;
    facesInImages: FaceDataFromImages;
    onNewPersonAdded: (person: { person_key: string; person_name: string, first_name: string, last_name: string, profile_url: string}) => void;
}

const API_URL = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/faces";

export function ConfirmFacesModalContent({
                                             userId,
                                             auth,
                                             facesInImages,
    onNewPersonAdded

                                         }: ConfirmFacesModalContentProps) {

    const [unnamedFaces, setUnnamedFaces] = useState<UnnamedFaceInImage[]>(facesInImages.unnamed_faces);
    const [namedFaces, setNamedFaces] = useState<NamedFaceInImage[]>(facesInImages.named_faces);
    const [confirmedFaceStacks, setConfirmedFaceStacks] = useState<FaceStack[]>([]);
    const [peopleInDatabase, setPeopleInDatabase] = useState<People[]>(facesInImages.people);
    const [expandedStack, setExpandedStack] = useState<string | null>(null);

    const [editingName, setEditingName] = useState<string>("");
    const [hoveredCard, setHoveredCard] = useState<string>("");
    const [editingValue, setEditingValue] = useState<string>("");
    const [tags, setTags] = useState<People[]>([]);

    // Process named faces into stacks whenever namedFaces changes
    useEffect(() => {
        const stacks: { [key: string]: NamedFaceInImage[] } = {};

        namedFaces.forEach(face => {
            const stackKey = `${face.person_key}-${face.person_name}`;
            if (!stacks[stackKey]) {
                stacks[stackKey] = [];
            }
            stacks[stackKey].push(face);
        });

        const newStacks: FaceStack[] = Object.values(stacks).map(faces => ({
            faces,
            stacked: true
        }));

        setConfirmedFaceStacks(newStacks);
    }, [namedFaces]);

    const handleAutocompleteChange = (value: string, face: UnnamedFaceInImage) => {
        setEditingValue(value);
        const lowerCaseKnownPeople = peopleInDatabase.map(person => person.person_name.toLowerCase());
        const filteredKnownPeople = peopleInDatabase
            .filter(person => person.person_name.toLowerCase().startsWith(value.toLowerCase()))
            .slice(0, 3);

        const matchesStart = lowerCaseKnownPeople.some(person => person.startsWith(value.toLowerCase()));
        if (!matchesStart) {
            const addOption: People = {
                person_name: `+ Add "${value}"`,
                person_key: face.suggested_person_key || `new-${value}`
            };
            setTags([...filteredKnownPeople, addOption]);
        } else {
            setTags(filteredKnownPeople);
        }
    };

    const handleAutoCompleteSelect = (selectedPerson: People, face: UnnamedFaceInImage) => {
        if (selectedPerson.person_name.startsWith('+ Add')) {
            const newName = selectedPerson.person_name.match(/"(.*)"/)?.[1];
            if (newName) {
                const newPerson: People = {
                    person_name: newName,
                    person_key: selectedPerson.person_key,
                }
                nameAsNewPerson(face, newPerson);
            }
        } else {
            nameAsExistingPerson(face, selectedPerson);
        }
        setEditingName(""); // Clear editing state after selection
        setEditingValue(""); // Clear editing value
    };

    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, face: UnnamedFaceInImage) => {
        if (event.key === 'Enter') {
            const matchingPerson = tags.find(person => person.person_name.toLowerCase() === editingValue.toLowerCase());
            if (matchingPerson) {
                handleAutoCompleteSelect(matchingPerson, face);
            } else if (editingValue.trim()) {
                // Handle as new person if no match
                const newPerson: People = {
                    person_name: editingValue,
                    person_key: face.suggested_person_key || `new-${editingValue}`
                };
                nameAsNewPerson(face, newPerson);
            }
            setEditingName(""); // Clear editing state
            setEditingValue(""); // Clear editing value
        } else if (event.key === 'Tab') {
            event.preventDefault();
            if (tags.length > 0) {
                setEditingValue(tags[0].person_name);
            }
        }
    };

    const makeApiCall = async (personKey: string, faces: Array<{
        key: string,
        rekognition_id: string
    }>, action: 'confirm' | 'deny' | 'untrack' | 'label' | 'name') => {
        try {
            await axios.patch(API_URL, {
                uid: userId,
                key: personKey,
                faces,
                action,
            }, {
                headers: {
                    'Authorization': `Bearer ${auth.IdToken}`,
                    'Content-Type': 'application/json'
                }
            });
        } catch (error) {
            console.error(`Error ${action}ing face:`, error);
            throw new Error(`An error occurred while ${action}ing the face. Please try again.`);
        }
    };

    const confirmUnnamedFace = async (face: UnnamedFaceInImage) => {
        if (!face.suggested_person_key) return;

        try {
            await makeApiCall(
                face.suggested_person_key,
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'confirm'
            );

            // Convert to named face
            const namedFace: NamedFaceInImage = {
                ...face,
                confirmed: true,
                person_key: face.suggested_person_key,
                person_name: face.suggested_person_name || "Unknown"
            };

            setNamedFaces(prev => [...prev, namedFace]);
            setUnnamedFaces(prev => prev.filter(f => f.face_key !== face.face_key));
        } catch (error) {
            alert(error);
        }
    };

    const denyUnnamedFace = async (face: UnnamedFaceInImage) => {
        if (!face.suggested_person_key) return;

        try {
            await makeApiCall(
                face.suggested_person_key,
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'deny'
            );

            setUnnamedFaces(prev => prev.map(f =>
                f.face_key === face.face_key
                    ? {...f, suggested_person_name: undefined, suggested_person_key: undefined}
                    : f
            ));
        } catch (error) {
            alert(error);
        }
    };

    const untrackFace = async (face: UnnamedFaceInImage) => {
        try {
            await makeApiCall(
                face.suggested_person_key || '',
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'untrack'
            );

            setUnnamedFaces(prev => prev.filter(f => f.face_key !== face.face_key));
        } catch (error) {
            alert(error);
        }
    };

    const nameAsExistingPerson = async (face: UnnamedFaceInImage, person: People) => {
        try {
            await makeApiCall(
                person.person_key,
                [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                'label'
            );

            // Convert to named face
            const namedFace: NamedFaceInImage = {
                ...face,
                confirmed: true,
                person_key: person.person_key,
                person_name: person.person_name
            };

            setNamedFaces(prev => [...prev, namedFace]);
            setUnnamedFaces(prev => prev.filter(f => f.face_key !== face.face_key));
        } catch (error) {
            alert(error);
        }
    };

    const nameAsNewPerson = async (face: UnnamedFaceInImage, person: People) => {
        try {
            let newPersonKey: string;
            let firstName: string;
            let lastName: string;

            try {
                const response = await axios.patch(API_URL, {
                    uid: userId,
                    key: person.person_key,
                    newName: person.person_name,
                    faces: [{key: face.face_key, rekognition_id: face.face_rekognition_id}],
                    action: 'name',
                }, {
                    headers: {
                        'Authorization': `Bearer ${auth.IdToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                console.log(response.data);

                // Parse response.data.body as JSON
                const responseData = JSON.parse(response.data.body);
                console.log(responseData);

                if (responseData.status === 'success' && responseData.newPersonKey && responseData.firstName && responseData.lastName) {
                    newPersonKey = responseData.newPersonKey;
                    firstName = responseData.firstName;
                    lastName = responseData.lastName;

                    // Update the onNewPersonAdded call with the parsed names
                    onNewPersonAdded({
                        person_key: newPersonKey,
                        person_name: person.person_name,
                        first_name: firstName,
                        last_name: lastName,
                        profile_url: face.face_url,
                    });
                } else {
                    throw new Error(responseData.message || 'Failed to get new person key from response');
                }
            } catch (error) {
                console.error('Error naming face:', error);
                throw new Error('An error occurred while naming the face. Please try again.');
            }

            // Convert to named face using the new person key from the response
            const namedFace: NamedFaceInImage = {
                ...face,
                confirmed: true,
                person_key: newPersonKey,
                person_name: person.person_name
            };

            setNamedFaces(prev => [...prev, namedFace]);
            setUnnamedFaces(prev => prev.filter(f => f.face_key !== face.face_key));

            // Add new person to peopleInDatabase with the new person key
            setPeopleInDatabase(prev => [...prev, {
                person_name: person.person_name,
                person_key: newPersonKey
            }]);
        } catch (error) {
            const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
            alert(errorMessage);
        }
    }


    const renderStackCounter = (face: NamedFaceInImage, stackSize: number, index?: number) => (
        <Group
            gap={'3px'}
            style={{
                position: 'absolute',
                top: 5,
                right: 5,
                backgroundColor: 'rgba(48, 48, 48, 1.0)',
                borderRadius: '15%',
                cursor: 'pointer',
                padding: '2px 4px'
            }}
            onClick={(e) => {
                e.stopPropagation();
                if (index !== undefined) {
                    setExpandedStack(null);
                } else {
                    setExpandedStack(expandedStack === face.person_key ? null : face.person_key);
                }
            }}
        >
            <Text size="md" c="white" w="bold" pl={'2px'}>
                {index !== undefined ? `${index + 1} of ${stackSize}` : stackSize}
            </Text>
            <IconStack2 size={20} color="white"/>
        </Group>
    );

    const renderFaceCard = (face: NamedFaceInImage, stackSize: number = 1, index?: number) => (
        <div style={{
            position: 'relative',
            width: 'calc((100% - 17px) / 6)',
            margin: '1px'
        }}>
            <Card
                p={0}
                w="100%"
                style={{aspectRatio: '4 / 5', position: 'relative'}}
            >
                <Box style={{height: '80%', position: 'relative'}}>
                    <Image
                        src={face.face_url}
                        height="100%"
                        width="100%"
                        fit="cover"
                    />
                    {(stackSize > 1 || index !== undefined) && renderStackCounter(face, stackSize, index)}
                </Box>
                <Paper
                    style={{
                        height: '20%',
                        width: '100%',
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    bg="#303030"
                >
                    <Text
                        size="sm"
                        w="bold"
                        c="white"
                        ta="center"
                    >
                        {face.person_name}
                    </Text>
                </Paper>
            </Card>
        </div>
    );

    return (
        <>
            {/*todo: only render this paper component/confirmed faces section if confirmed faces > 1*/}
            <Paper shadow="sm" radius="lg" withBorder p="md" style={{backgroundColor: '#f0f0f0'}}>
                <Text size="lg" mb="md">Confirmed People In Event:</Text>
                {/* Confirmed Faces Section */}
                <Flex
                    direction="row"
                    wrap="wrap"
                    gap={{base: '1px', sm: '1px'}}
                    justify="flex-start"
                >
                    {confirmedFaceStacks.map((stack) => (
                        <React.Fragment key={stack.faces[0].person_key}>
                            {stack.stacked && expandedStack !== stack.faces[0].person_key ? (
                                renderFaceCard(stack.faces[0], stack.faces.length)
                            ) : (
                                stack.faces.map((face) => (
                                    renderFaceCard(face, stack.faces.length, stack.faces.indexOf(face))
                                ))
                            )}
                        </React.Fragment>
                    ))}
                </Flex>
            </Paper>
            {/*todo: please make the fieldset legend text larger.*/}
            {/*todo: only render this feildset component/unnamed faces section if confirmed faces > 1*/}
            <Fieldset mt="md" legend="Please confirm/name these faces:">
                <Flex
                    mt="md"
                    direction="row"
                    wrap="wrap"
                    gap={{base: '1px', sm: '1px'}}
                    justify="flex-start"
                >
                    {unnamedFaces.map((face) => (
                        <div key={face.face_key} style={{
                            position: 'relative',
                            width: 'calc((100% - 17px) / 6)',
                            cursor: 'pointer',
                            margin: '1px'
                        }}>
                            <Card
                                p={0}
                                w="100%"
                                style={{aspectRatio: '4 / 5'}}
                                onMouseEnter={() => setHoveredCard(face.face_key)}
                                onMouseLeave={() => setHoveredCard("")}

                            >
                                <Flex direction="column" h="100%">
                                    <Box style={{flexBasis: '80%', position: 'relative'}}>
                                        <Image
                                            src={face.face_url}
                                            height="100%"
                                            width="100%"
                                            fit="cover"
                                        />
                                    </Box>
                                    <Paper
                                        style={{
                                            flexBasis: '20%',
                                            width: '100%',
                                            borderTopLeftRadius: 0,
                                            borderTopRightRadius: 0
                                        }}
                                        bg="lightgray"
                                    >
                                        {editingName === face.face_key ? (
                                            <Autocomplete
                                                placeholder="Enter name"
                                                value={editingValue}
                                                onChange={(value) => handleAutocompleteChange(value, face)}
                                                data={tags.map(person => person.person_name)}
                                                onKeyDown={(e) => handleInputKeyDown(e, face)}
                                                onSubmit={(item) => {
                                                    const selectedPerson = tags.find(p => p.person_name === editingValue);
                                                    if (selectedPerson) {
                                                        handleAutoCompleteSelect(selectedPerson, face);
                                                    }
                                                }}
                                                onFocus={(e) => {
                                                    e.target.select();
                                                    setEditingValue(face.suggested_person_name || "");
                                                }}
                                                styles={{
                                                    root: {
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        paddingLeft: '3px',
                                                        paddingRight: '3px',
                                                        zIndex: 3
                                                    },
                                                    input: {
                                                        textAlign: 'center'
                                                    }
                                                }}
                                                autoFocus
                                            />
                                        ) : (
                                            hoveredCard === face.face_key ? (
                                                <Group grow preventGrowOverflow={false} wrap="nowrap" h="100%"
                                                       style={{paddingLeft: '5%', paddingRight: '5%'}}>
                                                    <Flex justify="center" align="center" w="15%" h="100%">
                                                        <Tooltip
                                                            label={face.suggested_person_name ? `This is not ${face.suggested_person_name}` : 'Untrack this face'}
                                                            withArrow
                                                            openDelay={500}
                                                            position="bottom"
                                                        >
                                                            <Button
                                                                variant="transparent"
                                                                color="dark"
                                                                onClick={() => face.suggested_person_name ? denyUnnamedFace(face) : untrackFace(face)}
                                                                p={0}
                                                                h="100%"
                                                                w="100%"
                                                            >
                                                                {face.suggested_person_name ? <IconX size={16}/> :
                                                                    <IconTrash size={16}/>}
                                                            </Button>
                                                        </Tooltip>
                                                    </Flex>
                                                    <Flex justify="center" align="center" w="70%" h="100%">
                                                        <Text
                                                            size="sm"
                                                            ta="center"
                                                            c="dark"
                                                            style={{cursor: 'pointer'}}
                                                            onClick={() => setEditingName(face.face_key)}
                                                        >
                                                            {face.suggested_person_name ? `${face.suggested_person_name}?` : "?"}
                                                        </Text>
                                                    </Flex>
                                                    <Flex justify="center" align="center" w="15%" h="100%">
                                                        {face.suggested_person_name ? (
                                                            <Tooltip
                                                                label={`Confirm face as ${face.suggested_person_name}`}
                                                                withArrow
                                                                openDelay={500}
                                                                position="bottom"
                                                            >
                                                                <Button
                                                                    variant="transparent"
                                                                    color="dark"
                                                                    onClick={() => confirmUnnamedFace(face)}
                                                                    p={0}
                                                                    h="100%"
                                                                    w="100%"
                                                                >
                                                                    <IconCheck size={16}/>
                                                                </Button>
                                                            </Tooltip>
                                                        ) : (
                                                            <Button
                                                                variant="transparent"
                                                                color="lightgray"
                                                                onClick={() => setEditingName(face.face_key)}
                                                                p={0}
                                                                h="100%"
                                                                w="100%"
                                                            >
                                                                <IconPencil size={16}/>
                                                            </Button>
                                                        )}
                                                    </Flex>
                                                </Group>
                                            ) : (
                                                <Flex justify="center" align="center" h="100%">
                                                    <Text
                                                        size="sm"
                                                        ta="center"
                                                        c="dark"
                                                        style={{cursor: 'pointer'}}
                                                        onClick={() => setEditingName(face.face_key)}
                                                    >
                                                        {face.suggested_person_name ? `${face.suggested_person_name}?` : "?"}
                                                    </Text>
                                                </Flex>
                                            )
                                        )}
                                    </Paper>
                                </Flex>
                            </Card>
                        </div>
                    ))}
                </Flex>
            </Fieldset>
        </>
    );
}