import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Center, Group, Avatar, TextInput, LoadingOverlay } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { Person } from "../../types/Person";
import { useDebouncedCallback } from 'use-debounce';

interface PersonalInfoPanelProps {
    member: Person;
    onUpdate: (updatedFields: Partial<Person>) => Promise<void>;
    isUpdating: boolean;
}

export const PersonalInfoPanel: React.FC<PersonalInfoPanelProps> = React.memo(({
                                                                                   member,
                                                                                   onUpdate,
                                                                                   isUpdating
                                                                               }) => {
    console.log('PersonalInfoPanel rendered', { memberId: member.id });

    // Local state for form fields
    const [firstName, setFirstName] = useState(member.firstName || '');
    const [lastName, setLastName] = useState(member.lastName || '');
    const [dateOfBirth, setDateOfBirth] = useState<Date | null>(
        member.dateOfBirth ? new Date(member.dateOfBirth) : null
    );

    // Track whether we have pending changes
    const [hasPendingChanges, setHasPendingChanges] = useState(false);

    // Refs to track last saved values
    const lastSavedRef = useRef({
        firstName: member.firstName || '',
        lastName: member.lastName || '',
        dateOfBirth: member.dateOfBirth || ''
    });

    // Ref to prevent concurrent saves
    const isSavingRef = useRef(false);

    // Function to check if there are unsaved changes
    const hasUnsavedChanges = useCallback(() => {
        const hasChanges = firstName !== lastSavedRef.current.firstName ||
            lastName !== lastSavedRef.current.lastName ||
            dateOfBirth?.toISOString() !== lastSavedRef.current.dateOfBirth;

        console.log('Checking unsaved changes:', { hasChanges });
        return hasChanges;
    }, [firstName, lastName, dateOfBirth]);

    // Actual save function
    const saveChanges = async (updates: Partial<Person>) => {
        if (isSavingRef.current) {
            console.log('Save already in progress, skipping');
            return;
        }

        console.log('Saving changes:', updates);
        isSavingRef.current = true;
        setHasPendingChanges(false);

        try {
            await onUpdate(updates);
            console.log('Save successful');
            // Update last saved values
            lastSavedRef.current = {
                ...lastSavedRef.current,
                ...updates
            };
        } catch (error) {
            console.error('Failed to save updates:', error);
            // Revert to last saved values on error
            setFirstName(lastSavedRef.current.firstName);
            setLastName(lastSavedRef.current.lastName);
            if (lastSavedRef.current.dateOfBirth) {
                setDateOfBirth(new Date(lastSavedRef.current.dateOfBirth));
            }
        } finally {
            isSavingRef.current = false;
        }
    };

    // Debounced save function for text inputs
    const debouncedSave = useDebouncedCallback(
        (updates: Partial<Person>) => {
            console.log('Debounce timer expired, attempting save');
            if (hasUnsavedChanges()) {
                saveChanges(updates);
            }
        },
        3000,
        { maxWait: 5000 }
    );

    // Handle text input changes
    const handleTextChange = useCallback((field: 'firstName' | 'lastName', value: string) => {
        console.log(`Text changed for ${field}:`, value);
        setHasPendingChanges(true);

        if (field === 'firstName') {
            setFirstName(value);
        } else {
            setLastName(value);
        }

        debouncedSave({
            [field]: value
        });
    }, [debouncedSave]);

    // Handle text input blur
    const handleBlur = useCallback(() => {
        console.log('Input blur detected');
        if (hasPendingChanges) {
            console.log('Saving on blur due to pending changes');
            debouncedSave.flush();
        }
    }, [hasPendingChanges, debouncedSave]);

    // Handle date changes
    const handleDateChange = useCallback((newDate: Date | null) => {
        console.log('Date value changed:', newDate);
        setDateOfBirth(newDate);
        setHasPendingChanges(true);
    }, []);

    // Handle date input blur
    const handleDateBlur = useCallback(() => {
        if (dateOfBirth?.toISOString() !== lastSavedRef.current.dateOfBirth) {
            console.log('Saving date change on blur');
            saveChanges({
                dateOfBirth: dateOfBirth?.toISOString() || undefined
            });
        }
    }, [dateOfBirth, saveChanges]);

    // Sync with external changes to member props
    useEffect(() => {
        console.log('Member props changed, syncing state');
        if (member.firstName !== lastSavedRef.current.firstName) {
            setFirstName(member.firstName || '');
            lastSavedRef.current.firstName = member.firstName || '';
        }
        if (member.lastName !== lastSavedRef.current.lastName) {
            setLastName(member.lastName || '');
            lastSavedRef.current.lastName = member.lastName || '';
        }
        if (member.dateOfBirth !== lastSavedRef.current.dateOfBirth) {
            const newDate = member.dateOfBirth ? new Date(member.dateOfBirth) : null;
            setDateOfBirth(newDate);
            lastSavedRef.current.dateOfBirth = member.dateOfBirth || '';
        }
    }, [member.firstName, member.lastName, member.dateOfBirth]);

    // Save unsaved changes on unmount
    useEffect(() => {
        return () => {
            console.log('Component unmounting');
            if (hasPendingChanges && !isSavingRef.current) {
                console.log('Saving pending changes before unmount');
                if (dateOfBirth?.toISOString() !== lastSavedRef.current.dateOfBirth) {
                    saveChanges({
                        dateOfBirth: dateOfBirth?.toISOString() || undefined
                    });
                }
                debouncedSave.flush();
            }
        };
    }, [hasPendingChanges, debouncedSave, dateOfBirth]);

    return (
        <Box mt={20} p={20} pos="relative">
            <LoadingOverlay visible={isUpdating} />
            <Center mb={30}>
                <Avatar
                    src={member.profilePictureUrl}
                    radius="xl"
                    alt={`${member.name}`}
                    size={180}
                />
            </Center>
            <Group grow align="flex-start">
                <TextInput
                    size="md"
                    label="First Name"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={(e) => handleTextChange('firstName', e.target.value)}
                    onBlur={handleBlur}
                    disabled={isUpdating}
                    required
                />
                <TextInput
                    size="md"
                    label="Last Name"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={(e) => handleTextChange('lastName', e.target.value)}
                    onBlur={handleBlur}
                    disabled={isUpdating}
                    required
                />
            </Group>
            <DateInput
                size="md"
                label="Date of Birth"
                placeholder="MM/DD/YYYY"
                value={dateOfBirth}
                onChange={handleDateChange}
                onBlur={handleDateBlur}
                valueFormat="MM/DD/YYYY"
                disabled={isUpdating}
                required
                mt="md"
                allowDeselect={false}
                clearable={false}
                preserveTime={false}
                maxLength={10}
            />
        </Box>
    );
});

PersonalInfoPanel.displayName = 'PersonalInfoPanel';