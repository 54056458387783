import React, { useState, useEffect } from 'react';
import { Flex, Card, Avatar, Text, Blockquote, Title, Divider, Skeleton } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import axios from 'axios';
import { PaperProps } from '@mantine/core/lib';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { PersonCard } from './PersonCard';
import { PersonSkeleton} from './PersonSkeleton';

// Import types
import { Person, LifeEvent } from '../../types/Person';

interface FamilyProps extends PaperProps {
    userId: string;
    auth: AuthenticationResultType;
}


const API_BASE_URL = "https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production";

const getAuthHeaders = (auth: AuthenticationResultType) => ({
    'Authorization': `Bearer ${auth.IdToken}`,
    'Content-Type': 'application/json'
});

export function FamilyTree({ userId, auth }: FamilyProps) {
    const [isLoading, setIsLoading] = useState(true);
    const [familyMembers, setFamilyMembers] = useState<Person[]>([]);
    const [familyMembersBasic, setFamilyMembersBasic] = useState<Person[]>([]);
    const [updateError, setUpdateError] = useState<string | null>(null);

    // Function to create basic person objects
    const createBasicPersons = (members: Person[]): Person[] => {
        return members.map(member => ({
            id: member.id,
            firstName: member.firstName || '',
            lastName: member.lastName || '',
            // Required fields with empty defaults
            name: '',
            dateOfBirth: '',
            profilePictureUrl: ''
        }));
    };

    useEffect(() => {
        loadFamilyMembers();
    }, []);

    // Update familyMembersBasic whenever familyMembers changes
    useEffect(() => {
        setFamilyMembersBasic(createBasicPersons(familyMembers));
    }, [familyMembers]);

    const loadFamilyMembers = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/person`, {
                params: {
                    sub: userId
                },
                headers: getAuthHeaders(auth)
            });

            console.log(response.data.body);

            // Parse the response body if it's a string
            const responseData = typeof response.data.body === 'string'
                ? JSON.parse(response.data.body)
                : response.data.body;

            // Transform the backend data to match our frontend Person type
            const familyMembers: Person[] = responseData.map((member: any) => ({
                id: member.id,
                name: member.name,
                firstName: member.firstName,
                lastName: member.lastName,
                dateOfBirth: member.dateOfBirth,
                profilePictureUrl: member.profilePictureUrl,
                notes: member.notes || null,
                relationships: member.relationships?.map((rel: any) => ({
                    type: rel.type || null,
                    name: rel.person?.name || null,
                    person: rel.person ? {
                        id: rel.person.id,
                        name: rel.person.name,
                        firstName: rel.person.firstName,
                        lastName: rel.person.lastName,
                        dateOfBirth: rel.person.dateOfBirth,
                        profilePictureUrl: rel.person.profilePictureUrl
                    } : undefined
                })),
                lifeEvents: member.lifeEvents?.map((event: any) => ({
                    type: event.type || '',
                    date: event.date ? new Date(event.date) : null,
                    description: event.description,
                    isLocked: event.isLocked || false
                }))
            }));

            setFamilyMembers(familyMembers);
            // familyMembersBasic will be automatically updated via useEffect
        } catch (error) {
            console.error('Error fetching family members:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const onUpdate = async (updatedMember: Person) => {
        setUpdateError(null);
        try {
            // Transform the frontend Person type to match the backend's expected format
            const payload = {
                sub: userId,
                seed_key: updatedMember.id,
                firstName: updatedMember.firstName,
                lastName: updatedMember.lastName,
                dob: updatedMember.dateOfBirth,
                profilePictureUrl: updatedMember.profilePictureUrl,
                notes: updatedMember.notes,
                relationships: updatedMember.relationships?.map(rel => ({
                    type: rel.type,
                    person: rel.person ? {
                        id: rel.person.id
                    } : undefined
                })).filter(rel => rel.person?.id),
                lifeEvents: updatedMember.lifeEvents?.map(event => ({
                    type: event.type,
                    date: event.date?.toISOString().split('T')[0],
                    description: event.description,
                    isLocked: event.isLocked
                }))
            };

            const cleanPayload = Object.entries(payload).reduce((acc, [key, value]) => {
                if (value != null) {
                    acc[key] = value;
                }
                return acc;
            }, {} as any);

            const response = await axios.patch(
                `${API_BASE_URL}/person`,
                cleanPayload,
                {
                    headers: getAuthHeaders(auth)
                }
            );

            if (response.status === 200) {
                setFamilyMembers(prevMembers =>
                    prevMembers.map(member =>
                        member.id === updatedMember.id ? updatedMember : member
                    )
                );
                // familyMembersBasic will be automatically updated via useEffect
            } else {
                throw new Error('Failed to update family member');
            }
        } catch (error) {
            console.error('Error updating family member:', error);
            setUpdateError(error instanceof Error ? error.message : 'Failed to update family member');
            await loadFamilyMembers();
        }
    };

    return (
        <Flex direction="column">
            <Title order={1}>Identify Faces</Title>
            <Divider mt="xs" />
            {updateError && (
                <Text color="red" mt="xs" mb="xs">
                    {updateError}
                </Text>
            )}
            <Blockquote color="blue" icon={<IconInfoCircle />} mt="lg" mb="lg">
                <p>
                    Your family tree isn't just a record of the past—it's the key to organizing and preserving your memories. By adding family members and their birthdays, you enable Sort My Shoebox's AI to more accurately predict when your photos were taken, helping you better organize your photo collection.
                </p>
                <p>
                    In the future, this space will also serve as a hub for tracking and storing genealogical information, such as marriages, children, occupations, and significant life events. With AI-powered suggestions, we'll help uncover the hidden clues in each photograph as you piece together your family history.
                </p>
            </Blockquote>
            <Flex
                wrap="wrap"
                direction={{ base: 'column', sm: 'row' }}
                gap={{ base: '10px', sm: '10px' }}
                justify="flex-start"
            >
                {isLoading
                    ? Array(4).fill(null).map((_, index) => <PersonSkeleton key={`skeleton-${index}`} />)
                    : familyMembers.map((member) => (
                        <PersonCard
                            member={member}
                            onUpdate={onUpdate}
                            familyMembersBasic={familyMembersBasic}
                        />
                    ))}
            </Flex>
        </Flex>
    );
}