import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    useDisclosure,
    useMediaQuery
} from '@mantine/hooks';
import { AppShell, Stack, Burger, Flex, Center, Container, MantineProvider, Modal } from '@mantine/core';
import { LeftNavMenu } from './components/LeftNavMenu';
import TextComponent from './components/Text';
import './logo-font.css';
import '@mantine/carousel/styles.css';
import { IconChevronDown } from '@tabler/icons-react';
import {FamilyTree} from './components/familyTree/FamilyTree';
import SortOptionsMenu from './components/sortOptionsMenu';
import DeleteDuplicates from './components/DeleteDuplicates';
import ConfirmDates from './components/ConfirmDates';
import { Gallery } from './components/Gallery';
import {IdentifyFaces} from './components/IdentifyFaces';
import { NewIdentifyFaces } from './components/identifyFaces/NewIdentifyFaces';
import { GroupImages } from './components/GroupImages';
import { CommunityGallery } from './components/CommunityGallery';
import { FileUploader } from './components/FileUploader';
import { AuthenticationTitle, AuthStateOptions } from './components/authentication/Authenticate';
import { AuthenticationResultType, GetUserCommandOutput } from '@aws-sdk/client-cognito-identity-provider';
import { LandingPage } from './landing/LandingPage';
import { Header } from './landing/Header'
import {GroupImagesManually} from "./components/GroupImagesManually";

// Custom hook to handle back button
const useBackButton = (callback: () => void) => {
    useEffect(() => {
        window.history.pushState(null, '', window.location.pathname);
        window.addEventListener('popstate', callback);
        return () => {
            window.removeEventListener('popstate', callback);
        };
    }, [callback]);
};

export default function App() {
    const [opened, { toggle }] = useDisclosure();
    const [authenticated, setAuthenticated] = useState(false);
    const [auth, setAuth] = useState<AuthenticationResultType>();
    const [user, setUser] = useState<GetUserCommandOutput>();
    const [currentComponent, setCurrentComponent] = useState<string>('gallery');
    const [authAction, setAuthAction] = useState<AuthStateOptions | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [containerWidth, setContainerWidth] = useState<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    // this is a breakpoint to add extra padding for websites with a large pixel width
    const extraPadding = useMediaQuery('(min-width: 1440px)');

    const Component2: React.FC = () => <TextComponent />;

    const handleMenuItemClick = (component: string) => {
        setCurrentComponent(component);
    };


    const onSuccessfulSignOn = (authResults: AuthenticationResultType, userResults: GetUserCommandOutput) => {
        setAuthenticated(true);
        setAuth(authResults);
        setUser(userResults);
        console.log(authResults);
        console.log(userResults);
    };

    const handleAuthAction = (authState: AuthStateOptions.Login | AuthStateOptions.JoinWaitlist) => {
        console.log(authState);
        setAuthAction(authState);
        setIsModalOpen(true);  // Open the modal when auth action is triggered
        window.history.pushState(null, '', window.location.pathname);  // Add a new history entry
    };

    // Handle back button press
    useBackButton(() => {
        if (isModalOpen) {
            setIsModalOpen(false);
            setAuthAction(null);
        }
    });

    const renderContent = () => {
        switch (currentComponent) {
            case 'gallery':
                return <Gallery userId={user?.Username || ''} auth={auth!} />;
            case 'family tree':
                return <FamilyTree userId={user?.Username || ''} auth={auth!} />;
            case 'sort images':
                return <GroupImagesManually userId={user?.Username || ''} auth={auth!}/>;
                // return <SortOptionsMenu />;
            case 'remove duplicates':
                return <DeleteDuplicates userId={user?.Username || ''} auth={auth!} />;
            case 'confirm dates':
                return <ConfirmDates userId={user?.Username || ''} auth={auth!} />;
            case 'identify faces':
                return <NewIdentifyFaces userId={user?.Username || ''} auth={auth!} />;
                // return <IdentifyFaces userId={user?.Username || ''} auth={auth!} />;
            case 'verify groupings':
                return <CommunityGallery userId={user?.Username || ''} auth={auth!} />
            case 'import':
                return <FileUploader userId={user?.Username || ''} auth={auth!} idToken={auth?.IdToken} />;
            case 'ai event groups':
                return <GroupImages userId={user?.Username || ''} auth={auth!} />;
            case 'select event groups':
                return <GroupImagesManually userId={user?.Username || ''} auth={auth!}/>;
            default:
                return null;
        }
    };

    return (
        <MantineProvider>
            {authenticated ? (
                <AppShell
                    header={{ height: 85 }}
                    navbar={{
                        width: { base: '100%', sm: '300px' },
                        breakpoint: 'sm',
                        collapsed: { mobile: !opened },
                    }}
                    padding="md"
                >
                    <AppShell.Header>
                        <Container size="100%" h="100%">
                            <Flex justify="space-between" align="center" style={{ height: '100%', marginLeft: extraPadding ? "10vw" : "10px",
                                marginRight: extraPadding ? "10vw" : "10px" }}>
                                <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                                <Flex align="center">
                                    <img
                                        src="./logo.png"
                                        alt="Company Logo"
                                        style={{ width: 52, marginRight: 20, marginTop: -15 }}
                                    />
                                    <div className="oswald-logo">SORT MY SHOEBOX</div>
                                </Flex>
                                <Flex align="center">
                                    <img
                                        src="./profile.png"
                                        alt="profile picture"
                                        style={{ width: 45, marginRight: 15, marginTop: -5 }}
                                    />
                                    <div className="oswald-username">GRACE RAPER</div>
                                    <Flex style={{ padding: '5px 15px' }}>
                                        <IconChevronDown size="1.5rem" stroke={1.5} />
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Container>
                    </AppShell.Header>
                    <AppShell.Navbar p="md" style={{marginLeft: extraPadding ? "10vw" : "10px", borderRight: '1px solid #ccc' }}>

                        <Flex direction="column" style={{ height: '100%', justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                            <LeftNavMenu onItemClick={handleMenuItemClick} />
                        </Flex>

                    </AppShell.Navbar>

                    <AppShell.Main>
                        <Container size="100%"
                                   style={{
                                    marginLeft: extraPadding ? "10vw" : "10px",
                                    marginRight: extraPadding ? "10vw" : "10px"
                                }}
                                   ref={containerRef}>
                            {renderContent()}
                        </Container>
                    </AppShell.Main>
                </AppShell>
            ) : (
                <div>
                    {authAction == null && <LandingPage onAuthAction={handleAuthAction} />}

                        {authAction != null && (
                            <Stack
                                bg="var(--mantine-color-body)"
                                align="stretch"
                                justify="flex-start"
                                gap={0}  // Change this to 0 temporarily
                            >
                            <Header onLoginClick={() => handleAuthAction(AuthStateOptions.Login)} onRegisterClick={() => handleAuthAction(AuthStateOptions.JoinWaitlist)}></Header>
                            <AuthenticationTitle
                                onSuccessfulSignIn={onSuccessfulSignOn}
                                initialAuthState={authAction}
                            />
                            </Stack>
                        )}
                </div>
            )}
        </MantineProvider>
    );
}