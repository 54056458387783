import React, { useState } from 'react';
import { useForm } from '@mantine/form';
import { Anchor, Group, TextInput, Button, Stack, Alert, Text } from '@mantine/core';
import {AuthStateOptions} from './Authenticate';
import {IconArrowRight} from "@tabler/icons-react";

interface WaitlistProps {
    changeAuthState: (option: AuthStateOptions) => void;

}
const Waitlist: React.FC<WaitlistProps>= ({changeAuthState}) => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const form = useForm({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
        },
        validate: {
            firstName: (val) => (val.length ? null : 'First name is required'),
            lastName: (val) => (val.length ? null : 'Last name is required'),
            email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
        },
    });

    const handleSubmit = async (values: { firstName: string; lastName: string; email: string }) => {
        try {
            console.log('Form submitted with values:', values);
            const response = await fetch('https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production/user/join-waitlist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            });

            if (response.status !== 200) {
                throw new Error('Issue adding to waitlist');
            }
            console.log('user added to waitlist successfully');
            setFormSubmitted(true);
            setErrorMessage('');
        } catch (error) {
            console.error('error adding user to waitlist:', error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    return (
        <>
            <Text size="xl" fw={500} mb="md">
                Join Our Mailing List and Get Early Access!
            </Text>

            {errorMessage && (
                <Alert title="Error" color="red" mb="lg">
                    {errorMessage}
                </Alert>
            )}

            {formSubmitted ? (
                <Alert title="Success!" color="green" mb="lg">
                    Thank you for joining the waitlist!
                </Alert>
            ) : (
                <form onSubmit={form.onSubmit(handleSubmit)}>
                    <Stack>
                        <Group grow>
                            <TextInput
                                label="First Name"
                                withAsterisk
                                placeholder="Your first name"
                                value={form.values.firstName}
                                onChange={(event) => form.setFieldValue('firstName', event.currentTarget.value)}
                                error={form.errors.firstName}
                                radius="md"
                            />
                            <TextInput
                                label="Last Name"
                                withAsterisk
                                placeholder="Your last name"
                                value={form.values.lastName}
                                onChange={(event) => form.setFieldValue('lastName', event.currentTarget.value)}
                                error={form.errors.lastName}
                                radius="md"
                            />
                        </Group>

                        <TextInput
                            label="Email"
                            withAsterisk
                            placeholder="name@mail.com"
                            value={form.values.email}
                            onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                            error={form.errors.email}
                            radius="md"
                        />
                    </Stack>
                    <Group justify="space-between" mt="xl">
                        <Anchor component="button" type="button" c="dimmed" size="md"
                                onClick={() => changeAuthState(AuthStateOptions.Login)}
                                style={{ display: 'flex', alignItems: 'center' }}>
                            Already been Invited? Sign In
                            <IconArrowRight size={18} style={{ marginLeft: '4px' }} />
                        </Anchor>
                        <Button type="submit" radius="xl" color="blue">
                            Get Early Access
                        </Button>
                    </Group>
                </form>
            )}
        </>
    );
};

export default Waitlist;
