import React, { useEffect, useState, useRef, useCallback} from 'react';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { Blockquote, Button, Divider, Flex, Title, Text, Paper, Box, Modal } from '@mantine/core';
import { IconInfoCircle, IconArrowRight } from '@tabler/icons-react';
import { ImageSelectionGrid } from './ImageSelectionGrid';
import { EventDetailsForm } from './EventDetailsForm';
import { ConfirmFacesModalContent } from './ConfirmFacesForEvent';
import { NewPeopleModal } from './ConfirmNewPersonModal';
import { ImageData } from '../types/ImageData';
import {FaceDataFromImages, NewPerson} from '../types/Face';
import * as imageAPI from '../api/imageAPI';
import ImageWithSkeleton from "./ImageWithSkeleton";
import {RemoveDuplicatesModal} from './RemoveDuplicateModal';  // Add this import

interface GroupImagesManuallyProps {
    userId: string;
    auth: AuthenticationResultType;
}

export const GroupImagesManually = ({ userId, auth }: GroupImagesManuallyProps) => {
    // State management
    const [images, setImages] = useState<ImageData[]>([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(20);
    const [hasMore, setHasMore] = useState(true);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalPage, setModalPage] = useState(1);
    const [facesInImages, setFacesInImages] = useState<FaceDataFromImages | null>(null);
    const [newPeople, setNewPeople] = useState<NewPerson[]>([]);
    const [isPredicting, setIsPredicting] = useState(false);

    // Event details state
    const [eventDate, setEventDate] = useState<Date | null>(null);
    const [eventDescription, setEventDescription] = useState("");
    const [location, setLocation] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [dateFormat, setDateFormat] = useState<'month' | 'day'>('month');
    const [isDateAI, setIsDateAI] = useState(false);
    const [isEventDescriptionAI, setIsEventDescriptionAI] = useState(false);
    const [isLocationAI, setIsLocationAI] = useState(false);

    const [gridWidth, setGridWidth] = useState(0);
    const gridRef = useRef<HTMLDivElement>(null);

    // grahhhhh just ignoring the error because ive spent 4 hours on it and came up with nothing...
    // todo: fix actual issue and stop suppressing...
    useEffect(() => {
        window.addEventListener('error', e => {
            if (e.message.startsWith('ResizeObserver loop')) {
                const resizeObserverErrDiv = document.getElementById(
                    'webpack-dev-server-client-overlay-div'
                );
                const resizeObserverErr = document.getElementById(
                    'webpack-dev-server-client-overlay'
                );
                if (resizeObserverErr) {
                    resizeObserverErr.setAttribute('style', 'display: none');
                }
                if (resizeObserverErrDiv) {
                    resizeObserverErrDiv.setAttribute('style', 'display: none');
                }
            }
        });
    }, []);

    // Only set up resize observer when modal is closed
    useEffect(() => {
        if (modalOpen) {
            return; // Don't observe when modal is open
        }

        const updateWidth = () => {
            if (gridRef.current) {
                setGridWidth(gridRef.current.offsetWidth);
            }
        };

        // Initial measurement
        updateWidth();

        // Set up resize observer
        const resizeObserver = new ResizeObserver(updateWidth);
        if (gridRef.current) {
            resizeObserver.observe(gridRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [modalOpen]); // Only re-run when modalOpen changes


    // Fetch images on mount and page change
    useEffect(() => {
        const fetchImages = async () => {
            const result = await imageAPI.withErrorHandling(
                () => imageAPI.fetchUngroupedImages(page, limit, userId, auth)
            );

            if (result) {
                setImages(prev => [...prev, ...result.images]);
                setHasMore(result.hasMore);
            }
        };

        fetchImages();
    }, [page]);

    // Handle AI predictions
    useEffect(() => {
        if (isDateAI && eventDate && eventDate.getDate() !== 1) {
            setDateFormat('day');
        }
    }, [isDateAI, eventDate]);

    const fetchAIPredictions = async () => {
        setIsPredicting(true);
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.fetchAIPredictions(selectedImages, userId, auth)
        );

        if (result) {
            if (result.event_title) {
                setEventDescription(result.event_title);
                setIsEventDescriptionAI(true);
            }
            if (result.event_location) {
                setLocation(result.event_location);
                setIsLocationAI(true);
            }
            if (result.event_date) {
                setEventDate(new Date(result.event_date));
                setIsDateAI(true);
            }
            if (result.event_keywords) {
                setTags(result.event_keywords);
            }
        }
        setIsPredicting(false);
    };

    // Event handlers
    const handleImageSelect = (imageKey: string) => {
        setSelectedImages(prev =>
            prev.includes(imageKey)
                ? prev.filter(key => key !== imageKey)
                : [...prev, imageKey]
        );
    };

    const handleCreateEvent = async () => {
        if (selectedImages.length === 0) return;

        setLoading(true);
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.fetchFacesInImages(selectedImages, userId, auth)
        );

        if (result) {
            setFacesInImages(result);
            setModalOpen(true);
            setModalPage(1);
        }
        setLoading(false);
    };

    // Handle modal navigation
    const handleModalNext = async () => {
        const nextPage = modalPage + 1;
        if (nextPage === 4) {  // Changed from 3 to 4
            await fetchAIPredictions();
        }
        setModalPage(nextPage);
    };

    const confirmCreateEvent = async () => {
        const result = await imageAPI.withErrorHandling(
            () => imageAPI.createEvent({
                userId,
                imageKeys: selectedImages,
                eventDate,
                eventDescription,
                location,
                tags
            }, auth)
        );

        if (result) {
            setImages(prev => prev.filter(image => !selectedImages.includes(image.key)));
            setSelectedImages([]);
            setModalOpen(false);
            setEventDate(null);
            setEventDescription('');
            setLocation('');
            setTags([]);
            setModalPage(1);
        }
    };

    return (
        <div>
            <Flex direction="column" style={{ width: '100%', minHeight: '100vh', position: 'relative' }}>
                <Title order={1}>Manually Group Images</Title>
                <Divider mt="xs" />
                <Blockquote color="blue" icon={<IconInfoCircle />} mt="lg" mb="lg">
                    Select images that belong to the same event and click "Create Event" to group them together.
                    This manual method gives you full control over how your images are organized.
                </Blockquote>
                <div ref={gridRef}>
                    <ImageSelectionGrid
                        images={images}
                        selectedImages={selectedImages}
                        onImageSelect={handleImageSelect}
                        loading={loading}
                        hasMore={hasMore}
                        onLoadMore={() => setPage(prev => prev + 1)}
                        enableInfiniteScroll={!modalOpen} // Disable infinite scroll when modal is open
                    />
                </div>

                <Box style={{
                    backgroundColor: 'rgba(255, 255, 255, 0.75)',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 2,
                    width: gridWidth || '100%'
                }}>
                    <Flex justify="center">
                        <Button
                            mt="sm"
                            mb="sm"
                            size="md"
                            onClick={handleCreateEvent}
                            disabled={selectedImages.length === 0}
                            style={{width: '50%'}}
                        >
                            {selectedImages.length !== 0
                                ? `Create Event with ${selectedImages.length} Selected Image${selectedImages.length !== 1 ? 's' : ''}`
                                : 'Select Images to Create an Event'
                            }
                        </Button>
                    </Flex>
                </Box>
            </Flex>

            <Modal
                opened={modalOpen}
                size="80%"
                onClose={() => setModalOpen(false)}
                title="Create New Event"
                style={{
                    width: '80vw',
                    height: '80vh',
                    maxWidth: 'none',
                }}
            >
                {modalPage === 1 && facesInImages && (
                    <>
                        <ConfirmFacesModalContent
                            userId={userId}
                            auth={auth}
                            facesInImages={facesInImages}
                            onNewPersonAdded={(person) => setNewPeople(prev => [...prev, person])}
                        />
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14} />}
                        >
                            Next
                        </Button>
                    </>
                )}

                {modalPage === 2 && (
                    <>
                        <NewPeopleModal
                            userId={userId}
                            auth={auth}
                            newPeople={newPeople}
                            onUpdatePeople={setNewPeople}
                            onComplete={() => {
                                setModalPage(3);
                            }}
                        />
                        <Button
                            mt="xl"
                            fullWidth
                            onClick={handleModalNext}
                            rightSection={<IconArrowRight size={14} />}
                        >
                            Next
                        </Button>
                    </>
                )}

                {modalPage === 3 && (
                    <RemoveDuplicatesModal
                        userId={userId}
                        auth={auth}
                        selectedImages={selectedImages}
                        onComplete={handleModalNext}
                    />
                )}

                {modalPage === 4 && (
                    <>
                        <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
                            <Text fw={500}>{"Images Included in Event:"}</Text>
                            <Flex
                                mt="xs"
                                direction="row"
                                wrap="wrap"
                                gap={{ base: '3px', sm: '3px' }}
                                justify={selectedImages.length < 4 ? 'center' : 'flex-start'}
                            >
                                {selectedImages.slice(0, 3).map(key => (
                                    <div
                                        key={key}
                                        style={{
                                            position: 'relative',
                                            width: 'calc((100% - 9px) / 4)',
                                            aspectRatio: 1 / 1
                                        }}
                                    >
                                        <ImageWithSkeleton
                                            src={images.find(image => image.key === key)?.thumbnail_url || ''}
                                            alt="Thumbnail"
                                            onClick={() => {}}
                                        />
                                    </div>
                                ))}
                                {selectedImages.length > 3 && (
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: 'calc((100% - 9px) / 4)',
                                            aspectRatio: 1 / 1
                                        }}
                                    >
                                        <ImageWithSkeleton
                                            src={images.find(image => image.key === selectedImages[3])?.thumbnail_url || ''}
                                            alt="Thumbnail"
                                            onClick={() => {}}
                                        />
                                        {selectedImages.length > 4 && (
                                            <div style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                color: 'white',
                                                zIndex: 10
                                            }}>
                                                +{selectedImages.length - 4}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </Flex>
                        </Paper>

                        <EventDetailsForm
                            eventDate={eventDate}
                            eventDescription={eventDescription}
                            location={location}
                            tags={tags}
                            dateFormat={dateFormat}
                            isDateAI={isDateAI}
                            isEventDescriptionAI={isEventDescriptionAI}
                            isLocationAI={isLocationAI}
                            isPredicting={isPredicting}
                            onDateChange={(date) => {
                                setEventDate(date);
                                setIsDateAI(false);
                            }}
                            onDescriptionChange={(value) => {
                                setEventDescription(value);
                                setIsEventDescriptionAI(false);
                            }}
                            onLocationChange={(value) => {
                                setLocation(value);
                                setIsLocationAI(false);
                            }}
                            onTagsChange={setTags}
                            onDateFormatChange={setDateFormat}
                            onConfirm={confirmCreateEvent}
                        />
                    </>
                )}
            </Modal>
        </div>
    );
};