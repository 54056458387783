import React from 'react';
import { Card, Skeleton } from '@mantine/core';

export const PersonSkeleton: React.FC = () => (
    <Card
        shadow="xs"
        radius="md"
        style={{
            width: 'calc((100% - 30px) / 4)',
            aspectRatio: '1.0',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    >
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Skeleton height={120} width={120} mb={20} radius="xl" />
            <Skeleton height={20} width={120} mb={10} />
            <Skeleton height={15} width={80} />
        </div>
    </Card>
);