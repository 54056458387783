import React from 'react';
import { Box, Group, Flex, Button, Burger, Drawer, ScrollArea, Divider } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { rem } from '@mantine/core'; // Assuming rem utility is imported for size handling
import classes from './HeaderMegaMenu.module.css';

interface HeaderProps {
    onLoginClick: () => void; // onClick function for Login button
    onRegisterClick: () => void; // onClick function for Register/Sign up button
}

export const Header: React.FC<HeaderProps> = ({ onLoginClick, onRegisterClick }) => {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);

    return (
        <Box>
            {/* Fixed header with padding */}
            <header
                className={classes.header}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1000, // Ensures header stays on top of other elements
                    padding: '25px 40px 20px', // Add padding (top, left/right, bottom)
                    background: '#ffffff',
                    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)', // Optional shadow for better visual separation
                }}
            >
                <Group justify="space-between" h="100%">
                    <Flex align="center">
                        <img src="./logo.png" alt="Company Logo" style={{ width: 52, marginRight: 20, marginTop: -15 }} />
                        <div className="oswald-logo">SORT MY SHOEBOX</div>
                    </Flex>

                    <Group h="100%" gap={0} visibleFrom="sm">
                        <a href="#hero" className={classes.link}>Home</a>
                        <a href="#features" className={classes.link}>Features</a>
                        <a href="#pricing" className={classes.link}>Pricing</a>
                        {/*<a href="#demo" className={classes.link}>Demo</a>*/}
                    </Group>

                    <Group visibleFrom="sm">
                        <Button variant="default" onClick={onLoginClick}>Log in</Button>
                        <Button onClick={onRegisterClick}>Sign up</Button>
                    </Group>

                    <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="sm" />
                </Group>
            </header>

            <Drawer
                opened={drawerOpened}
                onClose={closeDrawer}
                size="100%"
                padding="md"
                title="Navigation"
                hiddenFrom="sm"
                zIndex={1000000}
            >
                <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                    <Divider my="sm" />
                    <a href="#hero" className={classes.link}>Home</a>
                    <a href="#features" className={classes.link}>Features</a>
                    <a href="#pricing" className={classes.link}>Pricing</a>
                    {/*<a href="#demo" className={classes.link}>Demo</a>*/}
                    <Divider my="sm" />
                    <Group justify="center" grow pb="xl" px="md">
                        <Button variant="default" onClick={onLoginClick}>Log in</Button>
                        <Button onClick={onRegisterClick}>Sign up</Button>
                    </Group>
                </ScrollArea>
            </Drawer>
        </Box>
    );
};
