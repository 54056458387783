import React, { useState, useEffect, useCallback } from 'react';
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { Group, rem, Text, Image, Center, Flex, ActionIcon, RingProgress, Notification } from '@mantine/core';
import { IconUpload, IconPhoto, IconCheck } from '@tabler/icons-react';
import { Dropzone, DropzoneProps, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { v4 as uuidv4 } from 'uuid';
import './FileUploader.css';

interface BaseDemoProps {
    userId: string;
    auth: any;
    idToken: string | undefined;
}

interface UploadingFile {
    file: FileWithPath;
    fileId: string;
    uploaded: boolean;
    processed: boolean;
}

export const FileUploader: React.FC<BaseDemoProps> = ({ userId, idToken }, props: Partial<DropzoneProps>) => {
    const [files, setFiles] = useState<UploadingFile[]>([]);
    const [uploading, setUploading] = useState(false);
    const [ws, setWs] = useState<WebSocket | null>(null);

    const connectWebSocket = useCallback(() => {
        const webSocket = new WebSocket(`wss://98gmbtml6j.execute-api.us-west-2.amazonaws.com/production/?userId=${userId}`);

        webSocket.onopen = () => console.log('WebSocket connected');

        webSocket.onmessage = (event) => {
            try {
                const message = JSON.parse(event.data);
                if (message.status === 'completed' && message.image_key) {
                    const messageFileId = message.image_key.split('/')[1];
                    setFiles(prevFiles =>
                        prevFiles.map(file => file.fileId === messageFileId ? { ...file, processed: true } : file)
                    );
                }
            } catch (error) {
                console.error('Error parsing WebSocket message:', error);
            }
        };

        webSocket.onerror = (error) => console.error('WebSocket error:', error);
        webSocket.onclose = () => {
            console.log('WebSocket closed. Attempting to reconnect...');
            setTimeout(connectWebSocket, 3000);
        };

        setWs(webSocket);
    }, [userId]);

    useEffect(() => {
        connectWebSocket();
        return () => {
            if (ws) ws.close();
        };
    }, [connectWebSocket]);

    const notifyImageUpload = useCallback((imageKey: string) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ action: 'imageUploaded', userId, imageKey }));
        } else {
            console.error('WebSocket connection is not open');
        }
    }, [ws, userId]);

    const handleDrop = async (acceptedFiles: FileWithPath[]) => {
        if (!idToken) {
            console.error('No ID token available. User might not be authenticated.');
            return;
        }

        setUploading(true);

        const s3Client = new S3Client({
            region: 'us-west-2',
            credentials: fromCognitoIdentityPool({
                client: new CognitoIdentityClient({ region: 'us-west-2' }),
                identityPoolId: 'us-west-2:d32fd088-c287-4f6c-8ca0-66e160c30a0f',
                logins: {
                    'cognito-idp.us-west-2.amazonaws.com/us-west-2_h68AazbCA': idToken,
                },
            }),
        });

        const newFiles = acceptedFiles.map(file => ({
            file,
            fileId: uuidv4(),
            uploaded: false,
            processed: false,
        }));

        setFiles(prevFiles => [...prevFiles, ...newFiles]);

        for (const newFile of newFiles) {
            try {
                const command = new PutObjectCommand({
                    Bucket: 'sort-my-shoebox-demo',
                    Key: `${userId}/${newFile.fileId}`,
                    Body: newFile.file,
                    ContentType: newFile.file.type,
                });

                await s3Client.send(command);
                notifyImageUpload(`${userId}/${newFile.fileId}`);

                setFiles(prevFiles =>
                    prevFiles.map(file => file.fileId === newFile.fileId ? { ...file, uploaded: true } : file)
                );
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }

        setUploading(false);
    };

    const previews = files.map(({ file, uploaded, processed, fileId }) => {
        const imageUrl = URL.createObjectURL(file);
        return (
            <div key={fileId} style={{
                position: 'relative',
                width: 'calc((100% - 12px) / 4)',
                aspectRatio: 1 / 1,
            }}>
                <Image src={imageUrl} style={{aspectRatio: 1 / 1}} onLoad={() => URL.revokeObjectURL(imageUrl)}/>
                {uploaded && (
                    <ActionIcon color="teal" variant="filled" radius="xl" size="sm"
                                style={{position: 'absolute', top: '3px', right: '3px'}}>
                        <IconCheck size="15px"/>
                    </ActionIcon>
                )}
                {processed && (
                    <ActionIcon color="blue" variant="filled" radius="xl" size="sm"
                                style={{position: 'absolute', top: '3px', left: '3px'}}>
                        <IconCheck size="15px"/>
                    </ActionIcon>
                )}
            </div>
        );
    });

    const uploadedCount = files.filter(file => file.uploaded).length;
    const processedCount = files.filter(file => file.processed).length;
    const totalCount = files.length;
    const uploadProgress = totalCount > 0 ? (uploadedCount / totalCount) * 100 : 0;

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <Center style={{width: '100%'}}>
                <Dropzone
                    onDrop={handleDrop}
                    maxSize={10 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE}
                    {...props}
                    style={{width: '100%', backgroundColor: 'var(--mantine-color-gray-light)'}}
                >
                    <Group justify="center" gap="xl" mih={220} style={{pointerEvents: 'none', width: '100%'}}>
                        <Dropzone.Accept>
                            <IconUpload
                                style={{width: '52px', height: '52px', color: 'var(--mantine-color-blue-6)'}}
                                stroke={1.5}
                            />
                        </Dropzone.Accept>
                        <Dropzone.Idle>
                            <IconPhoto
                                style={{width: '52px', height: '52px', color: 'var(--mantine-color-dimmed)'}}
                                stroke={1.5}
                            />
                        </Dropzone.Idle>
                        <div>
                            <Text size="xl" inline>
                                Drag images here or click to select files
                            </Text>
                            <Text size="sm" c="dimmed" inline mt={7}>
                                Attach as many files as you like, each file should not exceed 10mb
                            </Text>
                        </div>
                    </Group>
                </Dropzone>
            </Center>

            <Flex
                mt="md"
                direction="row"
                wrap="wrap"
                gap={{base: '3px', sm: '3px'}}
                justify={{sm: '3px'}}
            >
                {previews}
            </Flex>

            <Notification
                icon={
                    totalCount === processedCount ? (
                        <RingProgress
                            ml={'sm'} mr={'sm'}
                            sections={[{value: 100, color: 'teal'}]}
                            label={
                                <Center>
                                    <ActionIcon color="teal" variant="light" radius="xl" size="xl">
                                        <IconCheck size="20px"/>
                                    </ActionIcon>
                                </Center>
                            }
                            size={50}
                            thickness={4}
                        />
                    ) : (
                        <RingProgress
                            ml={'sm'} mr={'sm'}
                            sections={[{value: uploadProgress, color: 'blue'}]}
                            label={
                                <Text c="blue" fw={700} ta="center" size="xl">
                                    {uploadProgress.toFixed(0)}%
                                </Text>
                            }
                            size={50}
                            thickness={4}
                        />
                    )
                }
                withBorder
                color={'none'}
                radius="lg"
                title={uploading ? 'Uploading...' : totalCount === processedCount ? 'All files uploaded' : 'Uploading files...'}
            >
                <Text>{uploadedCount}/{totalCount} files uploaded</Text>
            </Notification>
        </div>
    );
};