import { Container, Title, Text, Button } from '@mantine/core';
import classes from './Hero.module.css';
import React from "react";

interface HeroProps {
    onCallToActionClick: () => void; // onClick function for call to action button
}

export const Hero: React.FC<HeroProps> = ({ onCallToActionClick }) => {
    return (
        <div className={classes.hero} >
            <Container size="lg">
                <div className={classes.inner}>
                    <div className={classes.content}>
                        <Title className={classes.title}>
                            Preserve Your Family's Legacy,
                            {' '}
                            <Text
                                component="span"
                                inherit
                                variant="gradient"
                                gradient={{ from: 'blue', to: 'blue' }}
                            >
                                One Photo at a Time
                            </Text>
                        </Title>

                        <Text className={classes.description} mt={30}>
                            SortMyShoebox makes the process of digitizing and organizing old family photos effortless. Our AI-driven platform intelligently groups photos by events, estimates dates, and more. We go beyond simple digitization, offering unique insights that bring your memories to life.
                            {/*and With features like face recognition, duplicate removal, and photo restoration, we ensure your cherished memories are preserved and easily accessible.*/}
                            {/*SortMyShoebox transforms your scattered photo collections into a cohesive family story. Our AI-powered platform goes beyond simple digitization, offering unique insights that bring your memories to life.*/}
                        </Text>

                        <Button
                            variant="basic"
                            color={"blue"}
                            size="xl"
                            className={classes.control}
                            mt={40}
                            onClick={onCallToActionClick}
                        >
                            Start Your Archive
                        </Button>
                    </div>
                </div>
            </Container>
        </div>
    );
}