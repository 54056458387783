// EventDetailsForm.tsx
import React from 'react';
import {Fieldset, Group, TextInput, Button, FocusTrap, TagsInput, SegmentedControl, Tooltip, Text} from '@mantine/core';
import { MonthPickerInput, DatePickerInput } from '@mantine/dates';
import {IconCalendar, IconCalendarMonth, IconSparkles} from '@tabler/icons-react';

interface EventDetailsFormProps {
    eventDate: Date | null;
    eventDescription: string;
    location: string;
    tags: string[];
    dateFormat: 'month' | 'day';
    isDateAI: boolean;
    isEventDescriptionAI: boolean;
    isLocationAI: boolean;
    isPredicting: boolean;
    onDateChange: (date: Date | null) => void;
    onDescriptionChange: (value: string) => void;
    onLocationChange: (value: string) => void;
    onTagsChange: (value: string[]) => void;
    onDateFormatChange: (format: 'month' | 'day') => void;
    onConfirm: () => void;
}

interface DateFormatControlProps {
    dateFormat: 'month' | 'day';
    onDateFormatChange: (format: 'month' | 'day') => void;
}

const DateFormatControl = ({dateFormat, onDateFormatChange}: DateFormatControlProps) => (
    <SegmentedControl
        size="sm"
        value={dateFormat}
        onChange={(value: string) => onDateFormatChange(value as 'month' | 'day')}
        data={[
            {
                value: 'day',
                label: (
                    <Tooltip
                        openDelay={0.5}
                        label="specify day"
                        position="bottom"
                    >
                        <IconCalendar size={16}/>
                    </Tooltip>
                )
            },
            {
                value: 'month',
                label: (
                    <Tooltip
                        openDelay={0.5}
                        label="specify month"
                        position="bottom"
                    >
                        <IconCalendarMonth size={16}/>
                    </Tooltip>
                )
            }
        ]}
    />
);

export const EventDetailsForm = ({
                                     eventDate,
                                     eventDescription,
                                     location,
                                     tags,
                                     dateFormat,
                                     isDateAI,
                                     isEventDescriptionAI,
                                     isLocationAI,
                                     isPredicting,
                                     onDateChange,
                                     onDescriptionChange,
                                     onLocationChange,
                                     onTagsChange,
                                     onDateFormatChange,
                                     onConfirm
                                 }: EventDetailsFormProps) => (
    <Fieldset mt="md" legend="Add Event Details">
        <FocusTrap>
            <>
                <Text fw={500}>Date:</Text>
                <Group gap="sm" grow={false}>
                    {dateFormat === 'month' ? (
                        <MonthPickerInput
                            size="md"
                            leftSectionPointerEvents="none"
                            placeholder="Pick month"
                            value={eventDate}
                            onChange={onDateChange}
                            styles={{
                                input: isDateAI ? {
                                    backgroundColor: '#e0f7fa',
                                    fontStyle: 'oblique'
                                } : undefined
                            }}
                            w="100%" // Changed from 92% to 100%
                            style={{ flex: 1 }} // This makes it stretch to fill available space
                        />
                    ) : (
                        <DatePickerInput
                            size="md"
                            leftSectionPointerEvents="none"
                            placeholder="Pick date"
                            value={eventDate}
                            onChange={onDateChange}
                            styles={{
                                input: isDateAI ? {
                                    backgroundColor: '#e0f7fa',
                                    fontStyle: 'oblique'
                                } : undefined
                            }}
                            w="100%" // Changed from 92% to 100%
                            style={{ flex: 1 }} // This makes it stretch to fill available space
                        />
                    )}
                    <DateFormatControl dateFormat={dateFormat} onDateFormatChange={onDateFormatChange} />
                </Group>

                <TextInput
                    mt="md"
                    size={"md"}
                    leftSection={isEventDescriptionAI && <IconSparkles size={18} />}
                    label="Description:"
                    value={eventDescription}
                    onChange={(e) => onDescriptionChange(e.currentTarget.value)}
                    placeholder="e.g. Joel and Shannon's Wedding, Grace's Birthday, etc."
                    styles={{
                        input: isEventDescriptionAI ? {
                            backgroundColor: '#e0f7fa',
                            fontStyle: 'oblique'
                        } : undefined
                    }}
                />

                <TextInput
                    size={"md"}
                    mt="md"
                    leftSection={isLocationAI && <IconSparkles size={18} />}
                    label="Location:"
                    value={location}
                    onChange={(e) => onLocationChange(e.currentTarget.value)}
                    placeholder=""
                    styles={{
                        input: isLocationAI ? {
                            backgroundColor: '#e0f7fa',
                            fontStyle: 'oblique'
                        } : undefined
                    }}
                />

                <TagsInput
                    size={"md"}
                    mt="md"
                    label="Keyword Tags:"
                    placeholder="Enter tag"
                    value={tags}
                    onChange={onTagsChange}
                    clearable
                />

                <Button
                    mt="md"
                    fullWidth
                    onClick={onConfirm}
                    disabled={isPredicting}
                >
                    Confirm
                </Button>
            </>
        </FocusTrap>
    </Fieldset>
);
