import React from 'react';
import {
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
    List,
    ThemeIcon,
    Button,
    Divider,
    Box
} from '@mantine/core';
import { IconPhoto, IconUser, IconUsers, IconCheck, IconX } from '@tabler/icons-react';
import classes from './Pricing.module.css';

type Feature = {
    name: string;
    getting: string | boolean;
    solo: string | boolean;
    family: string | boolean;
};



const features: Feature[] = [
    { name: 'Photo Upload Limit', getting: '200 images analyzed & stored', solo: '5,000 images analyzed & stored', family: '25,000 images analyzed & stored' },
    { name: 'Event Clustering', getting: true, solo: true, family: true },
    { name: 'Smart Date Estimation', getting: true, solo: true, family: true },
    { name: 'Face Recognition and AI-Tagging', getting: true, solo: true, family: true },
    { name: 'Intelligent Duplicate Removal', getting: false, solo: true, family: true },
    { name: 'Context-Aware Captions*', getting: false, solo: true, family: true },
    { name: 'Photo Restoration*', getting: false, solo: true, family: true },
    { name: 'Kinship Detection*', getting: false, solo: true, family: true },
    { name: 'Easy Sharing*', getting: false, solo: true, family: true },
    { name: 'Photo Digitization App*', getting: false, solo: true, family: true },
    { name: 'Manage Multiple Users*', getting: false, solo: false, family: true },
];

const pricingData = [
    {
        title: 'Getting Started',
        description: 'Perfect for Beginners',
        price: 'Free',
        icon: IconPhoto,
        popular: false,
        planKey: 'getting',
    },
    {
        title: 'Solo Historian',
        description: 'Our most popular plan',
        price: '$8 / month',
        icon: IconUser,
        popular: true,
        planKey: 'solo',
    },
    {
        title: 'Family Archivist',
        description: 'For large collections',
        price: '$20 / month',
        icon: IconUsers,
        popular: false,
        planKey: 'family',
    },
];

export function PricingCards() {
    const theme = useMantineTheme();

    const FeatureIcon = ({ included }: { included: boolean }) => (
        <ThemeIcon size={20} radius="xl" color={included ? 'blue' : 'red'}>
            {included ? <IconCheck size={rem(12)} /> : <IconX size={rem(12)} />}
        </ThemeIcon>
    );

    const plans = pricingData.map((plan) => (
        <Card
            key={plan.title}
            shadow="md"
            radius="md"
            className={classes.card}
            padding="xl"
            style={{ border: plan.popular ? `2px solid ${theme.colors.blue[6]}` : 'none' }}
        >
            <Box ta="center" mt="xs" mb="xs">
            <plan.icon
                style={{ width: rem(50), height: rem(50) }}
                stroke={2}
                color={theme.colors.blue[6]}
            />
            </Box>
            <Text ta="center" fz="xl" fw={700} className={classes.cardTitle} mt="sm">
                {plan.title}
            </Text>
            <Text ta="center" fz="sm" c="dimmed">
                {plan.description}
            </Text>
            <Box ta="center" mt="md" mb="md">
                <Text fz="2xl" fw={700}>
                    {plan.price}
                </Text>
            </Box>
            <List spacing="sm" size="sm" center>
                {features.map((feature, index) => (
                    <React.Fragment key={index}>
                        <List.Item
                            icon={<FeatureIcon included={!!(feature as any)[plan.planKey]} />}
                        >
                            {feature.name === 'Photo Upload Limit'
                                ? (feature as any)[plan.planKey]
                                : feature.name}
                        </List.Item>
                        {index < features.length - 1 && <Divider my="xs" />}
                    </React.Fragment>
                ))}
            </List>
            <Button fullWidth mt="xl" color={'blue'}>
                Start Now
            </Button>
        </Card>
    ));

    return (
        <Container size="lg" py="xl">
            <Title order={2} className={classes.title} ta="center" mt="sm">
                Preserve Your Photos, Your Way
            </Title>

            <Text c="dimmed" className={classes.description} ta="center" mt="md">
                Choose the perfect plan to bring your family's photographic history to life. Whether you're just starting out or managing a vast collection, we have the right tools for you.
            </Text>

            <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
                {plans}
            </SimpleGrid>
            <Box ta="center" mt="md" mb="md">
                <Text>
                    * = coming soon
                </Text>
            </Box>
        </Container>
    );
}