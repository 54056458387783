// src/api/imageApi.ts

import axios from 'axios';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { ImageData } from '../types/ImageData';
import { FaceDataFromImages, NewPerson } from '../types/Face';

const API_BASE_URL = 'https://mg27jllmfg.execute-api.us-west-2.amazonaws.com/production';

interface ApiResponse<T> {
    success: boolean;
    data?: T;
    error?: string;
}

const getAuthHeaders = (auth: AuthenticationResultType) => ({
    'Authorization': `Bearer ${auth.IdToken}`,
    'Content-Type': 'application/json'
});

const handleApiResponse = async <T>(
    apiCall: Promise<any>,
    errorMessage: string
): Promise<ApiResponse<T>> => {
    try {
        const response = await apiCall;
        console.log(response);

        let data = response.data;
        if (typeof data.body === 'string') {
            try {
                data = JSON.parse(data.body);
                console.log(data);
            } catch {
                data = data.body;
            }
        }

        return {
            success: true,
            data
        };
    } catch (error) {
        console.error(`${errorMessage}:`, error);
        return {
            success: false,
            error: errorMessage
        };
    }
};

export interface FetchImagesResult {
    images: ImageData[];
    hasMore: boolean;
}

// Fetch ungrouped images with pagination
export const fetchUngroupedImages = async (
    page: number,
    limit: number,
    userId: string,
    auth: AuthenticationResultType
): Promise<ApiResponse<FetchImagesResult>> => {
    const response = await handleApiResponse<unknown>(
        axios.get(`${API_BASE_URL}/gallery`, {
            params: {
                hideUnknown: true.toString(),
                sub: userId.toString(),
                page: page.toString(),
                limit: limit.toString(),
            },
            headers: getAuthHeaders(auth)
        }),
        'Failed to fetch images'
    );

    // Check if the response was successful
    if (!response.success || !response.data) {
        return response as ApiResponse<FetchImagesResult>;
    }

    // Add type checking to ensure we're working with an array
    if (!Array.isArray(response.data)) {
        console.log("unexpected:", response.data);
        return {
            success: false,
            error: 'Unexpected API response format: expected an array of images'
        };
    }

    // Now we can safely cast and filter the data
    const imageDataArray = response.data as ImageData[];
    const ungroupedImages = imageDataArray.filter(image => !image.event_key);

    // Return the expected FetchImagesResult format
    return {
        success: true,
        data: {
            images: ungroupedImages,
            hasMore: ungroupedImages.length === limit
        }
    };
};

// Fetch faces data for selected images
export const fetchFacesInImages = async (
    imageKeys: string[],
    userId: string,
    auth: AuthenticationResultType
): Promise<ApiResponse<FaceDataFromImages>> => {
    return handleApiResponse<FaceDataFromImages>(
        axios.post(
            `${API_BASE_URL}/faces/in-images`,
            {
                sub: userId,
                image_keys: imageKeys
            },
            {
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to fetch face suggestions'
    );
};

export const fetchFacesInAllImages = async (
    userId: string,
    auth: AuthenticationResultType
): Promise<ApiResponse<FaceDataFromImages>> => {
    console.log("fetching faces in all image")
    return handleApiResponse<FaceDataFromImages>(
        axios.get(
            `${API_BASE_URL}/faces`,
            {
                params: { sub: userId },
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to fetch face suggestions'
    );
};

export const fetchFacesForPerson = async (
    userId: string,
    auth: AuthenticationResultType
): Promise<ApiResponse<FaceDataFromImages>> => {
    console.log("fetching faces in all image")
    return handleApiResponse<FaceDataFromImages>(
        axios.get(
            `${API_BASE_URL}/faces`,
            {
                params: { sub: userId },
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to fetch face suggestions'
    );
};

// Fetch AI predictions for event details
export interface EventPredictions {
    event_title?: string;
    event_location?: string;
    event_date?: string;
    event_keywords?: string[];
}

// Helper function to adjust ISO date string to user's timezone
const adjustToLocalTimezone = (isoDateString: string): string => {
    console.log("orginal: ", isoDateString);
    // Create a date object from the ISO string
    const date = new Date(isoDateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return isoDateString;
    }

    // Get the local timezone offset in minutes
    const offsetMinutes = date.getTimezoneOffset();

    // Adjust the date by adding the offset
    date.setMinutes(date.getMinutes() + offsetMinutes);

    // Return the adjusted ISO string
    console.log("new: ", date.toISOString());
    return date.toISOString();
};

export const fetchAIPredictions = async (
    imageKeys: string[],
    userId: string,
    auth: AuthenticationResultType,
    replicateApiToken: string = 'your-api-token'
): Promise<ApiResponse<EventPredictions>> => {
    const response = await handleApiResponse<EventPredictions>(
        axios.post(
            `${API_BASE_URL}/event/predict-details`,
            {
                uid: userId,
                image_keys: imageKeys,
                replicate_api_token: replicateApiToken
            },
            {
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to fetch AI predictions'
    );

    // If the response was successful and contains an event_date, adjust it to local timezone
    if (response.success && response.data?.event_date) {
        return {
            ...response,
            data: {
                ...response.data,
                event_date: adjustToLocalTimezone(response.data.event_date)
            }
        };
    }

    return response;
};

// Create a new event
export interface CreateEventParams {
    userId: string;
    imageKeys: string[];
    eventDate?: Date | null; // Modified this line to accept null
    eventDescription: string;
    location: string;
    tags: string[];
}

export const createEvent = async (
    params: CreateEventParams,
    auth: AuthenticationResultType
): Promise<ApiResponse<void>> => {
    return handleApiResponse<void>(
        axios.post(
            `${API_BASE_URL}/event/new`,
            {
                sub: params.userId,
                include: params.imageKeys,
                exclude: [],
                eventDate: params.eventDate?.toISOString(), // The optional chaining operator will handle null values
                eventDescription: params.eventDescription,
                location: params.location,
                tags: params.tags
            },
            {
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to create event'
    );
};

// Add a new person
export const addNewPerson = async (
    person: NewPerson,
    userId: string,
    auth: AuthenticationResultType
): Promise<ApiResponse<void>> => {
    return handleApiResponse<void>(
        axios.post(
            `${API_BASE_URL}/person/new`,
            {
                sub: userId,
                ...person
            },
            {
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to add new person'
    );
};

// First, let's update the API function in imageAPI.ts to match the expected payload format

export const updatePerson = async (
    personKey: string,
    updates: {
        first_name?: string;
        last_name?: string;
        date_of_birth?: string;  // ISO string format
    },
    userId: string,
    auth: AuthenticationResultType
) => {
    // Transform the updates to match the backend's expected format
    const payload = {
        sub: userId,
        seed_key: personKey,
        firstName: updates.first_name,
        lastName: updates.last_name,
        dob: updates.date_of_birth,
    };

    console.log(payload);

    return handleApiResponse<void>(
        axios.patch(
            `${API_BASE_URL}/person`,
            payload,
            {
                headers: getAuthHeaders(auth)
            }
        ),
        'Failed to update person'
    );
};

// Error handling middleware
export const withErrorHandling = async <T>(
    apiCall: () => Promise<ApiResponse<T>>,
    onError?: (error: string) => void
): Promise<T | null> => {
    const response = await apiCall();

    if (!response.success) {
        if (onError) {
            onError(response.error || 'An unexpected error occurred');
        } else {
            console.error(response.error);
        }
        return null;
    }

    return response.data || null;
};