import React from 'react';
import { ThemeIcon, Text, Title, Container, SimpleGrid, rem, Group, Badge } from '@mantine/core';
import { IconCalendarTime, IconUsers, IconShare, IconScan, IconCopy, IconTags, IconPhoto, IconPuzzle, IconAbc, IconFaceId } from '@tabler/icons-react';
import classes from './Features.module.css';

const FEATURES = [
    {
        icon: IconPuzzle,
        comingSoon: false,
        title: 'Event Clustering',
        description: 'We\'ll automatically identify & group photos from the same event, making it easy to combine collections and relive special moments.'
    },
    {
        icon: IconCalendarTime,
        comingSoon: false,
        title: 'Smart Date Estimation',
        description: 'Our AI-engine analyzes clothing styles, image quality, and other contextual clues to estimate dates for your digital collection.'
    },
    {
        icon: IconAbc,
        comingSoon: false,
        title: 'Context-Aware Captions',
        description: 'Generate intelligent captions using image analysis and family tree info, preserving the story behind the image for future generations.'
    },
    {
        icon: IconCopy,
        comingSoon: false,
        title: 'Intelligent Duplicate Removal',
        description: 'We go beyond metadata to detect duplicate images by comparing the contents of each photo.'
    },
    {
        icon: IconTags,
        comingSoon: false,
        title: 'Face Recognition & AI Tagging',
        description: 'Automatically tag and find photos with our AI-engine that recognizes people, places, and activities.'
    },
    {
        icon: IconPhoto,
        comingSoon: true,
        title: 'Photo Restoration',
        description: 'Revive old or damaged photos with our cutting-edge restoration technology.'
    },
    {
        icon: IconUsers,
        title: 'Kinship Recognition',
        comingSoon: true,
        description: 'Discover family connections with facial analysis that suggests kinship relationships in your photos.'
    },
    {
        icon: IconShare,
        title: 'Easy Sharing',
        comingSoon: true,
        description: 'Easily share photos via social media or download links using our cloud storage system.'
    },
    {
        icon: IconScan,
        title: 'Photo Digitization',
        comingSoon: true,
        description: 'Use our smartphone app to scan and upload images directly to your SortMyShoebox account.'
    },
];


function Feature({ icon: Icon, title, description, comingSoon }: { icon: React.FC<any>; title: string; description: string; comingSoon: boolean }) {
    return (
        <div>
            <ThemeIcon variant="light" size={40} radius={40}>
                <Icon style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
            </ThemeIcon>
            <Group mt="sm" align="flex-end" justify={"space-between"} mb="xs">
                <Text fw={500}>
                    {title}
                </Text>
                {/*{comingSoon && <Badge color="blue">Coming Soon</Badge>}*/}
            </Group>
            <Text size="sm" c="dimmed" lh={1.6}>
                {description}
            </Text>
        </div>
    );
}

export function FeaturesGrid() {
    const features = FEATURES.map((feature, index) => <Feature {...feature} key={index} />);

    return (
        <Container className={classes.wrapper}>
            <Title className={classes.title} order={2}>Unlock Your Family's Legacy with SortMyShoebox</Title>

            <Container size={560} p={0}>
                <Text c="dimmed" className={classes.description} ta="center" mt="md">
                    Preserve, organize, and explore your family's photographic history with our innovative features.
                </Text>
            </Container>

            <SimpleGrid
                mt={60}
                cols={{ base: 1, sm: 2, md: 3 }}
                spacing={{ base: 'xl', md: 50 }}
                verticalSpacing={{ base: 'xl', md: 50 }}
            >
                {features}
            </SimpleGrid>
        </Container>
    );
}

export default FeaturesGrid;