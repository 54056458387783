import React, { useState } from 'react';
import { Modal, Title, Text, Button, Flex, Image, TextInput, Fieldset, Paper, Loader } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import * as imageAPI from '../api/imageAPI';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';

interface NewPerson {
    person_key: string;
    person_name: string;
    first_name: string;
    last_name: string;
    profile_url: string;
    date_of_birth?: Date;
}

interface NewPeopleModalProps {
    userId: string;
    auth: AuthenticationResultType;
    newPeople: NewPerson[];
    onUpdatePeople: (updatedPeople: NewPerson[]) => void;
    onComplete: () => void;
}

export const NewPeopleModal = ({ userId, auth, newPeople, onUpdatePeople, onComplete }: NewPeopleModalProps) => {
    const [updating, setUpdating] = useState<Record<string, boolean>>({});
    const [errors, setErrors] = useState<Record<string, string>>({});

    const isValidDate = (date: Date | null): date is Date => {
        if (!date) return false;
        return !isNaN(date.getTime()) && date <= new Date();
    };

    const handleDateChange = async (date: Date | null, personKey: string) => {
        // Update local state immediately for responsiveness
        const updatedPeople = newPeople.map(person =>
            person.person_key === personKey
                ? { ...person, date_of_birth: date || undefined }
                : person
        );
        onUpdatePeople(updatedPeople);

        // Only make API call if date is valid
        if (isValidDate(date)) {
            setUpdating(prev => ({ ...prev, [personKey]: true }));
            try {
                // TypeScript now knows date is not null here
                await imageAPI.withErrorHandling(() =>
                    imageAPI.updatePerson(
                        personKey,
                        {
                            date_of_birth: date.toISOString().split('T')[0]  // Format as YYYY-MM-DD
                        },
                        userId,
                        auth
                    )
                );
                setErrors(prev => ({ ...prev, [personKey]: '' }));
            } catch (error) {
                setErrors(prev => ({
                    ...prev,
                    [personKey]: 'Failed to update date of birth'
                }));
                // Revert local state on error
                onUpdatePeople(newPeople);
            } finally {
                setUpdating(prev => ({ ...prev, [personKey]: false }));
            }
        } else if (date) {
            // If date is invalid but not null, show error
            setErrors(prev => ({
                ...prev,
                [personKey]: 'Please enter a valid date'
            }));
        }
    };

    const handleInputChange = async (
        value: string,
        field: 'first_name' | 'last_name',
        personKey: string
    ) => {
        // Update local state immediately
        const updatedPeople = newPeople.map(person =>
            person.person_key === personKey
                ? { ...person, [field]: value }
                : person
        );
        onUpdatePeople(updatedPeople);

        // Update backend
        setUpdating(prev => ({ ...prev, [personKey]: true }));
        try {
            await imageAPI.withErrorHandling(() =>
                imageAPI.updatePerson(
                    personKey,
                    { [field]: value },
                    userId,
                    auth
                )
            );
            setErrors(prev => ({ ...prev, [personKey]: '' }));
        } catch (error) {
            setErrors(prev => ({
                ...prev,
                [personKey]: `Failed to update ${field.replace('_', ' ')}`
            }));
            // Revert local state on error
            onUpdatePeople(newPeople);
        } finally {
            setUpdating(prev => ({ ...prev, [personKey]: false }));
        }
    };

    return (
        <>
            <Title order={3} mb="md">New People Added</Title>
            <Text size="sm" mb="lg">Please add information for the new people you've identified:</Text>

            {newPeople.map(person => (
                <Paper
                    key={person.person_key}
                    shadow="sm"
                    radius="lg"
                    withBorder
                    p="md"
                    style={{backgroundColor: '#f0f0f0', marginBottom: '1rem'}}
                >
                    <Flex gap="md" align="stretch">
                        <div style={{
                            width: '200px',
                            flexShrink: 0,
                            display: 'flex',
                            alignItems: 'center'
                        }}>
                            <Image
                                src={person.profile_url}
                                alt={`face_thumbnail`}
                                fallbackSrc="https://placehold.co/400x400?text=Placeholder"
                                style={{
                                    aspectRatio: 1 / 1,
                                    cursor: 'pointer',
                                    width: '100%',
                                    height: 'auto',
                                    borderRadius: '45px',
                                    border: '1px solid grey',
                                }}
                            />
                        </div>

                        <Fieldset
                            style={{ flex: 1 }}
                            legend="Required Information"
                        >
                            {updating[person.person_key] && (
                                <Flex justify="center" align="center" style={{ position: 'absolute', right: '1rem', top: '1rem' }}>
                                    <Loader size="sm" />
                                </Flex>
                            )}
                            {errors[person.person_key] && (
                                <Text color="red" size="sm" mb="sm">
                                    {errors[person.person_key]}
                                </Text>
                            )}
                            <Flex gap="md" mb="md">
                                <TextInput
                                    label="First Name"
                                    placeholder="Enter first name"
                                    value={person.first_name}
                                    onChange={(event) => handleInputChange(
                                        event.currentTarget.value,
                                        'first_name',
                                        person.person_key
                                    )}
                                    required
                                    style={{ flex: 1 }}
                                    disabled={updating[person.person_key]}
                                />
                                <TextInput
                                    label="Last Name"
                                    placeholder="Enter last name"
                                    value={person.last_name}
                                    onChange={(event) => handleInputChange(
                                        event.currentTarget.value,
                                        'last_name',
                                        person.person_key
                                    )}
                                    required
                                    style={{ flex: 1 }}
                                    disabled={updating[person.person_key]}
                                />
                            </Flex>
                            <DateInput
                                valueFormat="MM/DD/YYYY"
                                label="Date of Birth"
                                placeholder="MM/DD/YYYY"
                                value={person.date_of_birth}
                                onChange={(date) => handleDateChange(date, person.person_key)}
                                required
                                disabled={updating[person.person_key]}
                                error={errors[person.person_key]?.includes('date') ? errors[person.person_key] : undefined}
                            />
                        </Fieldset>
                    </Flex>
                </Paper>
            ))}

            <Flex justify={{ sm: 'right' }}>
                <Button
                    mt="lg"
                    variant="outline"
                    color="blue"
                    onClick={onComplete}
                    disabled={Object.values(updating).some(Boolean)}
                >
                    Continue
                </Button>
            </Flex>
        </>
    );
};

export default NewPeopleModal;