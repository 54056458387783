import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Box, Button, Text, Select, Group, Stack, ActionIcon, LoadingOverlay } from '@mantine/core';
import { IconTrash, IconPlus } from '@tabler/icons-react';
import { Person, Relationship, RelationshipTypes, RelationshipType } from "../../types/Person";
import { useDebouncedCallback } from 'use-debounce';

interface RelationshipsPanelProps {
    member: Person;
    onUpdate: (updatedFields: Partial<Person>) => Promise<void>;
    isUpdating: boolean;
    familyMembersBasic: Person[];
}

export const RelationshipsPanel: React.FC<RelationshipsPanelProps> = React.memo(({
                                                                                     member,
                                                                                     onUpdate,
                                                                                     isUpdating,
                                                                                     familyMembersBasic
                                                                                 }) => {
    console.log('RelationshipsPanel rendered', {
        memberId: member.id,
        initialRelationships: member.relationships
    });

    const [relationships, setRelationships] = useState<Relationship[]>(() => {
        console.log('Initializing relationships state:', member.relationships);
        const initialRelationships = member.relationships || [];
        return initialRelationships.map(rel => ({
            ...rel,
            name: rel.person?.id || null // Store the person's ID as the name
        }));
    });

    // Track whether we have pending changes
    const [hasPendingChanges, setHasPendingChanges] = useState(false);

    // Refs to track last saved values and prevent concurrent saves
    const lastSavedRef = useRef<Relationship[]>(member.relationships || []);
    const isSavingRef = useRef(false);

    // Create family member select options, excluding the current member
    const familyMemberOptions = familyMembersBasic
        .filter(person => person.id !== member.id)
        .map(person => ({
            value: person.id,
            label: `${person.firstName} ${person.lastName}`.trim()
        }));

    // Function to check if there are unsaved changes
    const hasUnsavedChanges = useCallback(() => {
        const currentRelationships = JSON.stringify(relationships);
        const lastSaved = JSON.stringify(lastSavedRef.current);
        const hasChanges = currentRelationships !== lastSaved;

        console.log('Checking unsaved changes:', { hasChanges });
        return hasChanges;
    }, [relationships]);

    const saveChanges = async (updatedRelationships: Relationship[]) => {
        if (isSavingRef.current) {
            console.log('Save already in progress, skipping');
            return;
        }

        // Transform relationships to include the full person object
        const validRelationships = updatedRelationships
            .filter(rel => rel.type && rel.name) // First filter out incomplete relationships
            .map(rel => ({
                type: rel.type,
                name: rel.name,
                person: familyMembersBasic.find(p => p.id === rel.name)
            }))
            .filter(rel => rel.person !== undefined); // Filter out any relationships where we couldn't find the person

        console.log('Saving relationships:', validRelationships);
        isSavingRef.current = true;
        setHasPendingChanges(false);

        try {
            await onUpdate({ relationships: validRelationships });
            console.log('Save successful');
            lastSavedRef.current = validRelationships;
        } catch (error) {
            console.error('Failed to save updates:', error);
            setRelationships(lastSavedRef.current);
        } finally {
            isSavingRef.current = false;
        }
    };


    // Debounced save function
    const debouncedSave = useDebouncedCallback(
        (updatedRelationships: Relationship[]) => {
            console.log('Debounce timer expired, attempting save');
            if (hasUnsavedChanges()) {
                saveChanges(updatedRelationships);
            }
        },
        3000,
        { maxWait: 5000 }
    );

    const handleAddRelationship = useCallback(() => {
        const newRelationships = [...relationships, {
            type: null,
            name: null,
            person: undefined
        }];
        setRelationships(newRelationships);
        setHasPendingChanges(true);
    }, [relationships]);

    const handleRemoveRelationship = useCallback(async (index: number) => {
        const updatedRelationships = relationships.filter((_, i) => i !== index);
        setRelationships(updatedRelationships);
        setHasPendingChanges(true);
        debouncedSave(updatedRelationships);
    }, [relationships, debouncedSave]);

    const handleUpdateRelationship = useCallback((
        index: number,
        field: 'type' | 'name',
        value: string | null
    ) => {
        console.log('Updating relationship:', { index, field, value });

        const updatedRelationships = relationships.map((rel, i) => {
            if (i !== index) return rel;

            if (field === 'name') {
                // When updating name (person ID), also update the person object
                const selectedPerson = familyMembersBasic.find(p => p.id === value);
                return {
                    ...rel,
                    name: value,
                    person: selectedPerson
                };
            } else if (field === 'type') {
                // For type field, ensure it's cast as RelationshipType
                return {
                    ...rel,
                    type: value as RelationshipType | null
                };
            } else {
                return rel;
            }
        });

        console.log('Updated relationships:', updatedRelationships);
        setRelationships(updatedRelationships);
        setHasPendingChanges(true);
        debouncedSave(updatedRelationships);
    }, [relationships, familyMembersBasic, debouncedSave]);

    // Handle form field blur
    const handleBlur = useCallback(() => {
        console.log('Input blur detected');
        if (hasPendingChanges) {
            console.log('Saving on blur due to pending changes');
            debouncedSave.flush();
        }
    }, [hasPendingChanges, debouncedSave]);

    // Sync with external changes to member props
    useEffect(() => {
        console.log('Member relationships changed, syncing state');
        if (JSON.stringify(member.relationships) !== JSON.stringify(lastSavedRef.current)) {
            setRelationships(member.relationships || []);
            lastSavedRef.current = member.relationships || [];
        }
    }, [member.relationships]);

    // Save unsaved changes on unmount
    useEffect(() => {
        return () => {
            console.log('Component unmounting');
            if (hasPendingChanges && !isSavingRef.current) {
                console.log('Saving pending changes before unmount');
                debouncedSave.flush();
            }
        };
    }, [hasPendingChanges, debouncedSave]);

    return (
        <Box mt={20} p={20} pos="relative">
            <LoadingOverlay visible={isUpdating} />
            <Stack gap="md">
                {relationships.length === 0 ? (
                    <Text>No relationships have been added yet.</Text>
                ) : (
                    relationships.map((relationship, index) => (
                        <Group key={index} justify="space-between" align="center">
                            <Select
                                data={RelationshipTypes}
                                value={relationship.type || ''}
                                onChange={(value) => handleUpdateRelationship(index, 'type', value)}
                                onBlur={handleBlur}
                                placeholder="Select relationship type"
                                style={{ flex: 1 }}
                                disabled={isUpdating}
                            />
                            <Select
                                data={familyMemberOptions}
                                value={relationship.name || ''}
                                onChange={(value) => handleUpdateRelationship(index, 'name', value)}
                                onBlur={handleBlur}
                                placeholder="Select Person"
                                style={{ flex: 1 }}
                                disabled={isUpdating}
                            />
                            <ActionIcon
                                color="red"
                                onClick={() => handleRemoveRelationship(index)}
                                variant="outline"
                                disabled={isUpdating}
                            >
                                <IconTrash size={16} />
                            </ActionIcon>
                        </Group>
                    ))
                )}

                <Button
                    onClick={handleAddRelationship}
                    leftSection={<IconPlus size={16} />}
                    variant="outline"
                    disabled={isUpdating}
                >
                    Add Relationship
                </Button>
            </Stack>
        </Box>
    );
});

RelationshipsPanel.displayName = 'RelationshipsPanel';