import React, { useEffect, useState } from 'react';
import { AuthenticationResultType } from '@aws-sdk/client-cognito-identity-provider';
import { SuggestedFaceContent } from "./SuggestedFaceCards";
import * as imageAPI from '../../api/imageAPI';
import {Blockquote, Divider, Title, Flex} from "@mantine/core";
import {IconInfoCircle} from "@tabler/icons-react";

export interface FaceInImage {
    face_key: string;
    face_url: string;
    face_rekognition_id: string;
}

export interface NamedFaceInImage extends FaceInImage {
    confirmed: true;
    person_key: string;
    person_name: string;
}

export interface UnnamedFaceInImage extends FaceInImage {
    confirmed: false;
    suggested_person_key?: string;
    suggested_person_name?: string;
}

export interface People {
    person_name: string;
    person_key: string;
}

export interface NewPerson {
    person_key: string;
    person_name: string;
    first_name: string;
    last_name: string;
    profile_url: string;
    date_of_birth?: Date;
}

export interface FaceStack {
    faces: NamedFaceInImage[];
    stacked: boolean;
}

export interface FaceDataFromImages {
    people: People[];
    named_faces: NamedFaceInImage[];
    unnamed_faces: UnnamedFaceInImage[];
}

interface NewIdentifyFacesProps {
    userId: string;
    auth: AuthenticationResultType;
}

export const NewIdentifyFaces = ({ userId, auth }: NewIdentifyFacesProps) => {
    const [facesInImages, setFacesInImages] = useState<FaceDataFromImages | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [newPeople, setNewPeople] = useState<NewPerson[]>([]);

    useEffect(() => {
        const fetchFaces = async () => {
            try {
                setIsLoading(true);
                const response = await imageAPI.fetchFacesInAllImages(userId, auth);

                // Check if response and response.data exist
                if (response && response.data) {
                    setFacesInImages(response.data);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (err) {
                setError(err instanceof Error ? err.message : 'An error occurred while fetching faces');
            } finally {
                setIsLoading(false);
            }
        };

        fetchFaces();
    }, [userId, auth]);

    const handleNewPersonAdded = (person: NewPerson) => {
        setNewPeople(prev => [...prev, person]);

        // Update the facesInImages state to include the new person
        if (facesInImages) {
            setFacesInImages(prev => {
                if (!prev) return null;
                return {
                    ...prev,
                    people: [...prev.people, {
                        person_key: person.person_key,
                        person_name: person.person_name
                    }]
                };
            });
        }
    };

    if (isLoading) {
        return <div>Loading faces...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!facesInImages) {
        return <div>No face data available</div>;
    }

    return (
        <Flex direction='column'>
            <Title order={1}>Identify Faces</Title>
            <Divider mt='xs' />
            <Blockquote color="blue" icon={<IconInfoCircle />} mt='lg' mb='lg'>
                Identifying people in photos is crucial as it helps organize images accurately, enabling easy retrieval and browsing. Moreover, it enhances the AI's ability to predict ages, facilitating the estimation of when the photos were taken based on known birthdates.
            </Blockquote>
        <SuggestedFaceContent
            userId={userId}
            auth={auth}
            facesInImages={facesInImages}
            onNewPersonAdded={handleNewPersonAdded}
        />
        </Flex>
    );
};