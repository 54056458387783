import React, { useState } from 'react';
import { NavLink, Box, Flex } from '@mantine/core';
import {
    IconPhoto,
    IconFaceId,
    IconSeeding,
    IconBinaryTree,
    IconHandClick,
    IconPhotoX,
    IconUpload,
    IconProgressCheck,
    IconCalendarPlus,
    IconChevronRight,
    IconChevronDown
} from '@tabler/icons-react';

interface MenuItem {
    icon: React.ElementType;
    label: string;
    description?: string;
    rightSection?: React.ReactNode;
    rightSectionExpanded?: React.ReactNode;
    children?: MenuItem[];
}

interface LeftNavMenuProps {
    onItemClick: (component: string) => void;
}

const menuData: MenuItem[] = [
    { icon: IconPhoto, label: 'Gallery' },
    { icon: IconSeeding, label: 'Family Tree' },
    { icon: IconFaceId, label: 'Identify Faces' },
    { icon: IconPhotoX, label: 'Remove Duplicates' },
    { icon: IconProgressCheck, label: 'Sort Images', description: 'Organize photos with AI'},
    // {
    //     icon: IconProgressCheck,
    //     label: 'Sort Images',
    //     description: 'Organize photos with AI',
    //     rightSection: <IconChevronRight size="1rem" stroke={1.5} />,
    //     rightSectionExpanded: <IconChevronDown size="1rem" stroke={1.5} />,
    //     children: [
    //         { icon: IconBinaryTree, label: 'AI Event Groups' },
    //         { icon: IconHandClick, label: 'Select Event Groups' },
    //         { icon: IconCalendarPlus, label: 'Confirm Dates' },
    //
    //     ],
    // },
];

export const LeftNavMenu: React.FC<LeftNavMenuProps> = ({ onItemClick }) => {
    const [active, setActive] = useState<number | null>(null);

    const handleItemClick = (index: number, label: string) => {
        setActive(index === active ? null : index);
        onItemClick(label.toLowerCase());
    };

    const renderMenuItem = (item: MenuItem, index: number) => (
        <Box key={item.label}>
            <NavLink
                active={index === active}
                label={item.label}
                description={item.description}
                rightSection={index === active ? item.rightSectionExpanded : item.rightSection}
                leftSection={<item.icon size="2rem" stroke={1.5} />}
                onClick={() => handleItemClick(index, item.label)}
                variant="light"
            />
            {active === index && item.children && (
                <Box ml={16}>
                    {item.children.map(renderChildItem)}
                </Box>
            )}
        </Box>
    );

    const renderChildItem = (child: MenuItem) => (
        <NavLink
            key={child.label}
            label={child.label}
            description={child.description}
            rightSection={child.rightSection}
            leftSection={<child.icon size="1rem" stroke={1.5} />}
            onClick={() => onItemClick(child.label.toLowerCase())}
            variant="light"
        />
    );

    return (
        <Flex direction="column" justify="space-between" style={{ height: '100%' }}>
            <Box w="100%">
                {menuData.map(renderMenuItem)}
            </Box>
            <Box w="100%" mt="auto" pb="md">
                <NavLink
                    key="Import"
                    label="Import"
                    leftSection={<IconUpload size="2rem" stroke={1.5} />}
                    onClick={() => onItemClick("import")}
                    variant="filled"
                    active
                    styles={(theme) => ({
                        root: {
                            borderRadius: theme.radius.md,
                            '&:not(:last-of-type)': {
                                borderBottom: 'none',
                            },
                        },
                        label: {
                            fontWeight: 'bold', // This will make the "Import" label bold
                        },
                    })}
                />
            </Box>
        </Flex>
    );
};