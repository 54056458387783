import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Paper,
    Loader,
    Image,
    Title,
    Divider,
    Flex,
    Button,
    Center,
    Modal,
    TextInput,
    MultiSelect,
    PaperProps,
    Text,
    Fieldset,
    Stack,
    Skeleton, // Import Skeleton component
    FocusTrap
} from '@mantine/core';
import {IconArrowRight} from '@tabler/icons-react';
import {MonthPickerInput} from '@mantine/dates';
import {AuthenticationResultType} from "@aws-sdk/client-cognito-identity-provider";

interface SuggestedImage {
    key: string;
    thumbnail_url: string;
    image_url: string;
}

interface GroupData {
    community: number;
    suggested: SuggestedImage[];
}

interface AdditionalSuggestions {
    additionalImages: SuggestedImage[];
    keywords: string[];
    predictedDate: number;
    key: string;
}

interface CommunityGalleryProps extends PaperProps {
    userId: string;
    auth: AuthenticationResultType;
}

export function CommunityGallery({userId, auth, ...props}: CommunityGalleryProps) {
    const [groups, setGroups] = useState<GroupData[]>([]);
    const [selectedImages, setSelectedImages] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [additionalSuggestions, setAdditionalSuggestions] = useState<AdditionalSuggestions | null>(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [groupTitle, setGroupTitle] = useState("");
    const [location, setLocation] = useState("");
    const [keywords, setKeywords] = useState<string[]>([]);
    const [predictedDate, setPredictedDate] = useState<Date | null>(null);
    const [modalPage, setModalPage] = useState(1);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [imageLoadingStatus, setImageLoadingStatus] = useState<{ [key: string]: boolean }>({}); // Track image loading status

    useEffect(() => {
        fetchGalleryImages();
    }, []);

    const fetchGalleryImages = async () => {
        setIsLoading(true);
        try {
            const apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/groups";
            const id_token = auth.IdToken;
            const response = await axios.post<GroupData[]>(apiUrl, {}, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('API response:', response.data);
            setGroups(response.data);
            const initialSelected = response.data.map(group => group.suggested[0]?.key).filter(key => key);
            setSelectedImages(initialSelected);
            const initialLoadingStatus = response.data.reduce((acc, group) => {
                group.suggested.forEach(image => {
                    acc[image.key] = true; // Initially, all images are loading
                });
                return acc;
            }, {} as { [key: string]: boolean });
            setImageLoadingStatus(initialLoadingStatus);
        } catch (error) {
            console.error('Error fetching images:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageLoad = (key: string) => {
        setImageLoadingStatus(prevStatus => ({...prevStatus, [key]: false}));
    };

    const handleImageClick = (key: string) => {
        setSelectedImages(prevSelected =>
            prevSelected.includes(key) ? prevSelected.filter(k => k !== key) : [...prevSelected, key]
        );
    };

    const handleNoneSelected = () => {
        console.log('None of these images are from the same day');
    };

    const handleConfirmSelection = async () => {
        setIsModalLoading(true);
        setModalOpen(true);

        try {
            const selected = selectedImages;
            const unselected = groups.flatMap(group =>
                group.suggested.map(image => image.key).filter(key => !selected.includes(key))
            );

            const apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/create-group";
            const id_token = auth.IdToken;
            const response = await axios.post(apiUrl, {
                'selected_images': selected,
                'unselected_images': unselected,
                'key': selected[0] // Include the key of the first image in selectedImages
            }, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });

            let result = JSON.parse(response.data.body);
            console.log(result);
            setAdditionalSuggestions(result);
            setKeywords(result.keywords);
            let predictedDate = new Date(Math.floor(result.predictedDate), Math.floor(result.predictedDate * 12) % 12, 1);
            setPredictedDate(predictedDate);
        } catch (error) {
            console.error('Error confirming selection:', error);
        } finally {
            setIsModalLoading(false);
        }
    };

    const handleModalNext = () => {
        if (modalPage === 1) {
            setModalPage(2);
            handleConfirmSelection()
        } else if (modalPage === 2) {
            setModalPage(3);
        } else {
            setModalPage(1);
        }
    };

    const handleModalConfirm = async () => {
        try {
            const id_token = auth.IdToken;
            const apiUrl = "https://kc5a6afno5.execute-api.us-west-2.amazonaws.com/test3/updateEvent";
            const response = await axios.post(apiUrl, {
                'description': groupTitle,
                'location': location,
                'date': predictedDate?.toISOString(),
                'key': additionalSuggestions?.key
            }, {
                headers: {
                    'Authorization': `Bearer ${id_token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Event details posted:', response.data);
            // Fetch new group after confirming event details
            fetchGalleryImages();
        } catch (error) {
            console.error('Error posting event details:', error);
        } finally {
            setModalOpen(false);
            setModalTitle("Additional Images");
            setModalPage(1);
            setGroupTitle("");
            setLocation("");
            setKeywords([]);
            setPredictedDate(null);
            setSelectedImages([]);
            setAdditionalSuggestions(null);
        }
    };

    return (
        <Flex direction="column" style={{width: '100vw'}}>
            {isLoading ? (
                <Loader/>
            ) : (
                groups.map(group => (
                    <Paper shadow="sm" radius="lg" withBorder p="xl" key={group.community}
                           style={{marginBottom: '20px', padding: '10px', backgroundColor: '#f0f0f0'}}>
                        <Title order={4}>Select photos from the same day as:</Title>
                        <Divider style={{margin: '10px 0'}}/>
                        {group.suggested && group.suggested.length > 0 && (
                            <>
                                <Divider style={{margin: '10px 0'}}/>
                                <Title order={5}>Similar photos:</Title>
                                <Flex
                                    mt="md"
                                    direction="row"
                                    wrap="wrap"
                                    gap={{base: '3px', sm: '3px'}}
                                    justify="flex-start"
                                >
                                    {group.suggested.map(image => (
                                    // {group.suggested.slice(1).map(image => (
                                        <div style={{
                                            width: 'calc((100% - 12px) / 5)',
                                            aspectRatio: 1 / 1
                                        }}>
                                            <Image
                                                key={image.key}
                                                src={image.thumbnail_url}
                                                alt="Thumbnail"
                                                style={{
                                                    // width: 'calc((100% - 12px) / 5)',
                                                    aspectRatio: 1 / 1,
                                                    cursor: 'pointer',
                                                    border: selectedImages.includes(image.key) ? '3px solid blue' : 'none'
                                                }}
                                                onLoad={() => handleImageLoad(image.key)}
                                                onClick={() => handleImageClick(image.key)}
                                            />
                                            {imageLoadingStatus[group.suggested[0].key] && (
                                                <Skeleton/>
                                            )}
                                        </div>
                                    ))}
                                </Flex>
                                <Flex justify="space-between" mt="md">
                                    <Button onClick={handleNoneSelected}>None of these are from the same day</Button>
                                    <Button onClick={handleConfirmSelection}>Confirm selected photos</Button>
                                </Flex>
                            </>
                        )}
                    </Paper>
                ))
            )}
            <Modal opened={modalOpen} size="xl" onClose={() => setModalOpen(false)} title={"Create New Event"}>
                <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
                    <Text fw={500}>{"Images Included in Event:"}</Text>
                    <Flex
                        mt="xs"
                        direction="row"
                        wrap="wrap"
                        gap={{ base: '3px', sm: '3px' }}
                        justify={selectedImages.length < 4 ? 'center' : 'flex-start'}
                    >
                        {selectedImages.slice(0, 3).map(key => (
                            <div
                                style={{
                                    width: 'calc((100% - 9px) / 4)',
                                    aspectRatio: 1 / 1
                                }}
                            >
                                <Image
                                    key={key}
                                    src={groups[0].suggested.find(image => image.key === key)?.thumbnail_url}
                                    alt="Thumbnail"
                                    style={{
                                        // width: 'calc((100% - 9px) / 4)',
                                        aspectRatio: 1 / 1
                                    }}
                                    onLoad={() => handleImageLoad(key)}
                                />
                                {imageLoadingStatus[key] && (
                                    <Skeleton/>
                                )}
                            </div>
                        ))}
                        {selectedImages.length > 3 && (
                            <div
                                style={{
                                    position: 'relative',
                                    width: 'calc((100% - 9px) / 4)',
                                    aspectRatio: 1 / 1
                                }}
                            >
                                <Image
                                    key={selectedImages[3]}
                                    src={groups[0].suggested.find(image => image.key === selectedImages[3])?.thumbnail_url}
                                    alt="Thumbnail"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0
                                    }}
                                    onLoad={() => handleImageLoad(selectedImages[3])}
                                />
                                {selectedImages.length > 4 && (
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'white',
                                        zIndex: 10
                                    }}>
                                        +{selectedImages.length - 4}
                                    </div>
                                )}
                                {imageLoadingStatus[selectedImages[3]] && (
                                    <Skeleton/>
                                )}
                            </div>
                        )}
                    </Flex>
                </Paper>
                <Paper shadow="sm" radius="lg" withBorder p="md" style={{ backgroundColor: '#f0f0f0' }}>
                    <Text fw={500}>{"Images Included in Event:"}</Text>
                    <Flex
                        mt="xs"
                        direction="row"
                        wrap="wrap"
                        gap={{ base: '3px', sm: '3px' }}
                        justify={selectedImages.length < 4 ? 'center' : 'flex-start'}
                    >
                        {selectedImages.slice(0, 3).map(key => (
                            <div
                                style={{
                                width: 'calc((100% - 9px) / 4)',
                                aspectRatio: 1 / 1
                            }}
                            >
                                <Image
                                    key={key}
                                    src={groups[0].suggested.find(image => image.key === key)?.thumbnail_url}
                                    alt="Thumbnail"
                                    style={{
                                        // width: 'calc((100% - 9px) / 4)',
                                        aspectRatio: 1 / 1
                                    }}
                                    onLoad={() => handleImageLoad(key)}
                                />
                                {imageLoadingStatus[key] && (
                                    <Skeleton/>
                                )}
                            </div>
                        ))}
                        {selectedImages.length > 3 && (
                            <div
                                style={{
                                position: 'relative',
                                width: 'calc((100% - 9px) / 4)',
                                aspectRatio: 1 / 1
                            }}
                            >
                                <Image
                                    key={selectedImages[3]}
                                    src={groups[0].suggested.find(image => image.key === selectedImages[3])?.thumbnail_url}
                                    alt="Thumbnail"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0
                                    }}
                                    onLoad={() => handleImageLoad(selectedImages[3])}
                                />
                                {selectedImages.length > 4 && (
                                    <div style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: 'white',
                                        zIndex: 10
                                    }}>
                                        +{selectedImages.length - 4}
                                    </div>
                                )}
                                {imageLoadingStatus[selectedImages[3]] && (
                                    <Skeleton/>
                                )}
                            </div>
                        )}
                    </Flex>
                </Paper>


                {isModalLoading ? (
                    <Loader />
                ) : (
                    <>
                        {modalPage === 1 && additionalSuggestions && (
                            <>
                                <Fieldset mt="md" legend="Select any additional images of event:">
                                    <Flex
                                        mt="md"
                                        direction="row"
                                        wrap="wrap"
                                        gap={{ base: '3px', sm: '3px' }}
                                        justify="flex-start"
                                    >
                                        {additionalSuggestions.additionalImages.map(image => (
                                            <div style={{width: 'calc((100% - 12px) / 5)', aspectRatio: 1 / 1}}>
                                            <Image
                                                key={image.key}
                                                src={image.thumbnail_url}
                                                alt="Thumbnail"
                                                style={{
                                                    // width: 'calc((100% - 12px) / 5)',
                                                    aspectRatio: 1 / 1,
                                                    cursor: 'pointer',
                                                    border: selectedImages.includes(image.key) ? '3px solid blue' : 'none'
                                                }}
                                                onClick={() => handleImageClick(image.key)}
                                            />
                                            {/*todo: there also appear to be something wrong here... maybe these image keys arent being added to the loading status map. */}
                                            {/*todo: I also want to investigate if thumbnails are full size images*/}
                                            {imageLoadingStatus[image.key] && (
                                                <Skeleton/>
                                            )}
                                            </div>
                                        ))}
                                    </Flex>
                                </Fieldset>
                                <Flex justify="flex-end">
                                    <Button
                                        variant="outline"
                                        mt="md"
                                        onClick={handleModalNext}
                                        rightSection={<IconArrowRight size={14} />}
                                    >
                                        Next
                                    </Button>
                                </Flex>
                            </>
                        )}

                        {modalPage === 2 && additionalSuggestions && (
                            <>

                                <Fieldset mt="md" legend="Add Event Details (optional)">
                                    <FocusTrap>
                                        <>
                                            <TextInput
                                                label="Event Description:"
                                                value={groupTitle}
                                                placeholder={"e.g. Joel and Shannon's Wedding, Grace's Birthday, etc."}
                                                onChange={(event) => setGroupTitle(event.currentTarget.value)}
                                                mt="md"
                                            />
                                            <TextInput
                                                label="Location:"
                                                value={location}
                                                onChange={(event) => setLocation(event.currentTarget.value)}
                                                mt="md"
                                            />
                                        </>
                                    </FocusTrap>
                                </Fieldset>
                                <Flex justify="flex-end">
                                    <Button
                                        variant="outline"
                                        mt="md"
                                        onClick={handleModalNext}
                                        rightSection={<IconArrowRight size={14} />}
                                    >
                                        Next
                                    </Button>
                                </Flex>
                            </>
                        )}

                        {modalPage === 3 && additionalSuggestions && (
                            <>

                                <Fieldset mt="md" legend="Adjust AI date estimate (optional)">
                                    <FocusTrap>
                                        <>
                                            <MonthPickerInput
                                                clearable
                                                label="Estimated Event Date:"
                                                value={predictedDate}
                                                onChange={(date) => setPredictedDate(date)}
                                                mt="md"
                                            />
                                        </>
                                    </FocusTrap>
                                </Fieldset>
                                <Button onClick={handleModalConfirm} fullWidth mt="md">
                                    Confirm
                                </Button>
                            </>
                        )}
                    </>
                )}
            </Modal>
        </Flex>
    );
}